.my-class-modal{
    position: fixed;
    top: -10%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: #FFF;
    width: 75%;
    padding: 50px;
    z-index: 999;
    border-radius: 5px;
    display: flex;
    flex-direction: column
}

.my-class-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0 ;
    background-color: rgba(0,0,0,0.7);
}

.my-class-modal-close-button{
    position: fixed;
    top: 0;
    right: 0;
    border-top-right-radius: 5px;
    padding: 10px 15px 10px 15px;
    border:none;
    font-weight: 700;
}

.existing-roles-container{
    margin-bottom: 50px;
}

.existing-roles-content-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--secondary-color);
    border-radius: 5px;
    min-height: 50px;
    margin: 2.5px 0 2.5px 0;
}

.existing-role-object{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    background-color: var(--third-color);
    padding: 5px 5px 5px 15px;
    border-radius: 5px;
    margin: 10px;
    user-select: none;
}

.existing-role-object:hover{
    filter: brightness(1.2)
}


.existing-role-object button{
    border: none;
    background-color: transparent;
    font-weight: 700;
    font-size: 0.75rem;
}

.existing-role-label{
    font-size: 0.75rem;
    font-weight: 600;
}

.add-roles-container{
    width: 100%;
}

.add-roles-input-field{
    width: 25%;
    position: relative;
    font-size: 0.9rem;
}

.add-roles-input-field input{
    width: 100%;
    padding: 7.5px 20px 7.5px 20px;
    border: none;
    background-color: var(--secondary-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
}


.add-roles-add-button{
    border: none;
    background-color: rgb(221, 221, 221);
    padding: 7.5px 15px 7.5px 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 700;
    position: fixed;
    left: 25%;
}

.add-roles-save-button{
    margin-top: 15px;
    background-color: var(--main-color);
    padding: 7.5px 15px 7.5px 15px;
    border:none;
    border-radius:5px;
    font-weight: 600;
}

.add-roles-add-button:hover, .add-roles-save-button:hover, .my-class-modal-close-button:hover{
    filter: brightness(1.2)
}


.default-roles-to-add-container{
    margin: 5px 0 10px 0;
    background-color: var(--secondary-color);
}

.default-roles-to-add-container button{
    border: none;
    background-color: var(--third-color);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin: 7.5px;
    user-select: none;
    font-size: 10pt;
    font-weight: 600;
}