@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
	--main-color: #243245;
	--secondary-color: whitesmoke;
	--third-color: rgb(235, 210, 164);
	--bg: #242526;
	--bg-accent: #484a4d;
	--text-color: #dadce1;
	--reverse-text-color: black;
	--nav-size: 75px;
	--border: 1px solid #474a4d;
	--border-radius: 8px;
	--speed: 500ms;
}

body {
	min-width: 400px;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	color: var(--reverse-text-color);
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	color: var(--reverse-text-color);
}

* {
	font-family: 'Montserrat';
}

@media only screen and (max-width: 1050px) {
	.navbar {
		height: var(--nav-size);
		background-color: var(--main-color);
		display: flex;
		position: sticky;
		top: 0;
		z-index: 10;
		user-select: none;
		min-width: 400px;
	}

	.navbar-nav-left {
		height: 100%;
		justify-content: flex-start;
		align-content: flex-start;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-items: flex-start;
	}

	.navbar-nav-left-burger {
		display: flex;
		position: fixed;
		right: 50px;
		top: 20px;
	}

	.hamburger-icon {
		background-color: transparent;
		outline: none;
		border: none;
		transform: rotate(90deg);
		font-size: 25px;
		color:#ffce8a;

	}
	.hamburger-icon:hover {
		font-weight: 500;
	}

	.hamburger-dropdown {
		background-color: transparent;
		outline: none;
		border: none;
		padding-left: 15px;
		display: flex;
	}

	.hamburger-dropdown-ul {
		position: absolute;
		right: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		border-radius: 5px;
		background-color: #243245;
		width: 200px;
		transform: translateY(40px);
		flex-wrap: wrap;
	}

	.nav-item {
		display: flex;
		align-items: center;
		font-family: 'Montserrat';
		font-weight: 500;
		width: 100%;
		justify-items: center;
		align-content: center;
		justify-content: center;
		padding: 10px 0px 10px 0px;
		color: #ffce8a;
	}

	.nav-item:hover {
		background-color: #ffce8a;
		color: #243245
	}

	.nav-item:first-child:hover {
		background-color: #ffce8a;;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		color: #243245
	}

	.nav-item:last-child:hover {
		background-color: #ffce8a;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		color: #243245
	}

	.navbar-nav-right {
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
	}

	.navbar-nav-left {
		position:relative;
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.navbar-nav-left-items {
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 50px;
		display: none;
	}

	.nav-item-link-button {
		display: flex;
		align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: rgba(0, 0, 0, 0.74);
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text {
		display: flex;
		width: 100%;
		justify-content: center;
		color: white;
	}

	.nav-item-link-button-inverted {
		display: flex;
		align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: white;
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text-inverted {
		display: flex;
		width: 100%;
		justify-content: center;
		color: black;
	}

	.nav-item-link-button-text-inverted:hover {
		color: white;
	}

	.nav-item-dropdown {
		width: calc(var(--nav-size) * 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}

	.icon-button {
		--button-size: calc(var(--nav-size) * 0.5);
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		padding: 5px;
		margin: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: filter 300ms;
		color: black;
	}

	.icon-button:hover {
		filter: brightness(1.2);
	}

	.dp-icon {
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		--button-size: calc(var(--nav-size) * 0.5);
		border-radius: 50%;
	}

	.dp-button:hover {
		filter: brightness(1.2);
	}

	.drop-button {
		width: 15px;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logo {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 2.5%;
	}

	.logo-icon {
		height: 50px;
	}

	.logo-text {
		font-family: 'Comfortaa';
		font-size: large;
		color: black;
		text-decoration: none;
	}

	.logo-text:hover {
		text-decoration: none;
		color: black;
	}

	.language-div {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
	}

	.language-text {
		font-size: 11px;
		color: #ffce8a;
	}

	.drop-down-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;
	}

	.drop-down-menu ul {
		position: absolute;
		top: calc(var(--nav-size) * 0.6);
		right: calc(var(--nav-size) * 0.925);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		border-radius: 5px;
		background-color: var(--secondary-color);
		width: 17.5vh;
		transform: translateY(7.5px);
	}

	.drop-down-menu a {
		align-content: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: flex;
		padding: 15px 20px 15px 20px;
	}

	.drop-down-menu button {
		align-content: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: flex;
		padding: 15px 20px 15px 20px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.drop-down-menu a:first-child:hover {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.drop-down-menu a:hover {
		background-color: var(--third-color);
	}

	.drop-down-menu button:first-child:hover {
		background-color: transparent;
	}

	.drop-down-menu button:hover {
		background-color: var(--third-color);
	}

	.drop-down-menu button {
		border: none;
		background: transparent;
	}

	.sidebar-container {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		min-width: 900px;
		font-size: 14px;
	}

	.sidebar-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		user-select: none;
	}

	.sidebar-item img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item a {
		padding: 10px;
		width: 100%;
		height: 100%;
		transition: transform 0.2s;
	}

	.sidebar-item a:hover {
		background-color: var(--secondary-color);
		border-bottom: 2px solid var(--third-color);
		transform: translateY(2px);
	}

	.sidebar-item-active {
		display: flex;
		flex-direction: row;
		width: 16vw;
		align-items: center;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		user-select: none;
	}

	.sidebar-item-active img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item-active a {
		padding: 10px;
		width: 100%;
		height: 100%;
		transition: transform 0.2s ease-in-out;
	}

	.sidebar-main-content {
		padding: 10px;
		width: 100%;
		position: relative;
		word-wrap: break-word;
	}

	.topbar-container {
		background-color: var(--secondary-color);
		width: 100%;
		display: flex;
		flex-direction: row;
		position: relative;
	}

	.topbar-item {
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-content: center;
		transition: transform 0.2s ease-in-out;
	}

	.topbar-item:hover {
		transform: translateY(-1px);
		background-color: var(--secondary-color);
		border-bottom: 3px solid var(--third-color);
		cursor: pointer;
	}

	.topbar-item-active {
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-content: center;
		border-bottom: 3px solid var(--main-color);
		cursor: pointer;
		font-weight: 700;
		background-color: var(--secondary-color);
	}
}

@media only screen and (min-width: 1050px) {
	.navbar {
		height: var(--nav-size);
		background-color: var(--main-color);
		position: sticky;
		top: 0;
		z-index: 10;
		user-select: none;
	}

	.navbar-nav-right {
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		justify-items: center;
	}

	.navbar-nav-left {
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.navbar-nav-left-items {
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 50px;
	}

	.navbar-nav-left-items a {
		height: 100%;
		justify-items: center;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.nav-item {
		display: flex;
		align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 400;
		width: 90px;
		justify-items: center;
		justify-content: center;
		height: 100%;
		transition: transform 0.2s ease-in-out;
		color: #ffce8a;
	}

	.nav-item:hover {
		border-bottom: 2px solid var(--secondary-color);
		transform: translateY(-1px);
	}

	.nav-item-link-button {
		display: flex;
		align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: rgba(0, 0, 0, 0.74);
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text {
		display: flex;
		width: 100%;
		justify-content: center;
		color: white;
	}

	.nav-item-link-button-inverted {
		display: flex;
		align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: white;
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text-inverted {
		display: flex;
		width: 100%;
		justify-content: center;
		color: black;
	}

	.nav-item-link-button-text-inverted:hover {
		color: white;
	}

	.nav-item-dropdown {
		width: calc(var(--nav-size) * 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}

	.icon-button {
		--button-size: calc(var(--nav-size) * 0.5);
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		padding: 5px;
		margin: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: filter 300ms;
		color: black;
	}

	.icon-button:hover {
		filter: brightness(1.2);
	}

	.dp-icon {
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		--button-size: calc(var(--nav-size) * 0.5);
		border-radius: 50%;
	}

	.dp-button:hover {
		filter: brightness(1.2);
	}

	.drop-button {
		width: 15px;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logo {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 2.5%;
	}

	.logo-icon {
		height: 50px;
	}

	.logo-text {
		font-family: 'Comfortaa';
		font-size: large;
		color: black;
		text-decoration: none;
	}

	.logo-text:hover {
		text-decoration: none;
		color: black;
	}

	.language-div {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
	}

	.language-text {
		font-size: small;
		color: rgba(0, 0, 0, 0.5);
	}

	.drop-down-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;
	}

	.drop-down-menu ul {
		position: absolute;
		top: calc(var(--nav-size) * 0.6);
		right: calc(var(--nav-size) * 0.925);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		border-radius: 5px;
		background-color: var(--secondary-color);
		width: 17.5vh;
		transform: translateY(7.5px);
	}

	.drop-down-menu a {
		align-content: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: flex;
		padding: 15px 20px 15px 20px;
	}

	.drop-down-menu button {
		align-content: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: flex;
		padding: 15px 20px 15px 20px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.drop-down-menu a:first-child:hover {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.drop-down-menu a:hover {
		background-color: var(--third-color);
	}

	.drop-down-menu button:first-child:hover {
		background-color: transparent;
	}

	.drop-down-menu button:hover {
		background-color: var(--third-color);
	}

	.drop-down-menu button {
		border: none;
		background: transparent;
	}

	.navbar-nav-left-burger {
		display: none;
	}

	.sidebar-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 18vw;
		position: fixed;
	}

	.sidebar-item {
		display: flex;
		flex-direction: row;
		width: 16vw;
		align-items: center;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		user-select: none;
	}

	.sidebar-item img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item a {
		padding: 15px;
		width: 100%;
		height: 100%;
		transition: transform 0.2s ease-in-out;
		font-size: 16px;
	}

	.sidebar-item a:hover {
		background-color: var(--secondary-color);
		border-bottom: 2px solid var(--third-color);
		transform: translateX(3px);
	}

	.sidebar-item-active {
		display: flex;
		flex-direction: row;
		width: 16vw;
		align-items: center;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		user-select: none;
		font-size: 16px;
	}

	.sidebar-item-active img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item-active a {
		padding: 15px;
		width: 100%;
		height: 100%;
		transition: transform 0.2s ease-in-out;
	}

	.sidebar-main-content {
		padding: 10px;
		left: 18vw;
		width: 80vw;
		position: relative;
		word-wrap: break-word;
		min-height: 500px;
	}

	.topbar-container {
		background-color: var(--secondary-color);
		left: 18vw;
		width: 80vw;
		display: flex;
		flex-direction: row;
		position: relative;
	}

	.topbar-item {
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-content: center;
		transition: transform 0.2s ease-in-out;
	}

	.topbar-item:hover {
		transform: translateY(-1px);
		background-color: var(--secondary-color);
		border-bottom: 3px solid var(--third-color);
		cursor: pointer;
	}

	.topbar-item-active {
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-content: center;
		border-bottom: 3px solid var(--main-color);
		cursor: pointer;
		font-weight: 700;
		background-color: var(--secondary-color);
	}

	.class-list-table-container {
		width: 100%;
		border: 1px solid black;
		overflow: hidden;
	}

	.class-list-table-container td {
		overflow-wrap: break-word;
	}

	.column-header {
		border: 1px solid black;
		width: 100px;
	}
}
