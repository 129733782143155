.homepage-box {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	/* background-color: cornflowerblue; */
}

.homepage-main-box {
	width: 100%;
	min-width: 400px;
}

.homepage-search-box {
	width: 60%;
	height: 150px;
	/* background-color: darkkhaki; */
	margin-left: auto;
	margin-right: auto;
	margin-top: 100px;
	margin-bottom: 150px;
}

.homepage-search-bar {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	position: relative;
}

.homepage-search-bar-input {
	display: block;
	width: 100%;
	height: 50px;
	font-size: 12px;
	padding-left: 40px;
	font-size: 16pt;
	border: none;
	border-bottom: 3px solid rgb(204, 204, 204);
	color: gray;
	font-weight: 500;
}

.homepage-search-bar-input-enter {
	position: absolute;
	right: 15px;
	top: 20px;
	height: 20px;
	width: 75px;
	min-width: 75px;
	color: rgb(204, 204, 204);
	user-select: none;
}

.homepage-search-bar-input:focus {
	border-bottom: 3px solid rgb(63, 63, 63);
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.homepage-search-bar-input:focus ~ .search-icon {
	color: rgb(63, 63, 63);
	transform: scale(1.2);
}

.homepage-search-bar-input:focus ~ .homepage-search-bar-input-enter {
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.homepage-search-big-title {
	font-weight: 700;
	font-size: 40px;
	/* background-color: aliceblue; */
	color: #452724;
	/* letter-spacing: -2px; */
	line-height: 1;
}

.homepage-search-title {
	font-weight: 500;
	font-size: 24px;
	/* background-color: aliceblue; */
	margin-left: 5px;
	margin-bottom: 3px;
	margin-top: -10px;
	line-height: 1;
}

.homepage-search-subtitle {
	font-size: 16px;
	/* background-color: darkmagenta; */
	margin-bottom: 10px;
	margin-left: 5px;
}

/* Top 5 Books in Hong Kong */

.homepage-top-box {
	background-color: #fff7ec;
	padding-bottom: 50px;
	overflow: hidden;
}

.homepage-top-one-box {
	width: fit-content;
	/* background-color: aqua; */
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	padding-left: 70px;
}

.homepage-top-title {
	width: fit-content;
	font-weight: 700;
	font-size: 28px;
	/* background-color: aliceblue; */
	margin: 0;
	padding-top: 50px;
	margin-left: auto;
	margin-right: auto;
	color: #452724;
}

.homepage-top-one {
	text-align: center;
	overflow: hidden;
	width: 230px;
	height: 400px;
}

@media only screen and (min-width: 1166px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 1166px) and (min-width: 702px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-flow: dense;
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 702px) and (min-width: 480px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-flow: dense;
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 480px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-auto-flow: dense;
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}

	.homepage-top-title {
		width: fit-content;
		font-weight: 700;
		font-size: 28px;
		margin: 0;
		padding-top: 50px;
		margin-left: auto;
		margin-right: auto;
		color: #452724;
		max-width: 270px;
		text-align: center;
	}
}

.top-five-number {
	font-size: 280px;
	font-weight: 700;
	/* background-color: burlywood; */
	padding-right: 100px;
	margin-bottom: -250px;
	margin-top: -50px;
	color: rgba(36, 50, 69, 0.5);
	pointer-events: none;
}

.homepage-top-book-title {
	font-weight: 600;
	font-size: 16px;
	margin-left: 5px;
	margin-bottom: 0;
}

.homepage-top-book-author {
	font-weight: 300;
	font-size: 13px;
	margin-left: 5px;
	margin-top: -5px;
}

/* Genres */

.homepage-genre-box {
	/* background-color: cornflowerblue; */
	padding-bottom: 50px;
}

.homepage-genre-title {
	width: fit-content;
	font-weight: 700;
	font-size: 28px;
	/* background-color: aliceblue; */
	margin: 0;
	padding-top: 50px;
	margin-left: 5%;
	color: #452724;
}

.homepage-genre-subtitle {
	width: fit-content;
	font-size: 16px;
	/* background-color: aliceblue; */
	margin: 0;
	margin-left: 5%;
	margin-bottom: 10px;
}

@media only screen and (min-width: 884px) {
	.homepage-genre-grid {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 884px) and (min-width: 500px) {
	.homepage-genre-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 500px) {
	.homepage-genre-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		justify-content: space-evenly;
		align-content: space-between;
		align-items: center;
		justify-items: center;
	}
}

.homepage-genre-col {
	text-align: center;
	margin: 25px;
	width: 100px;
	height: 100px;
	align-self: center;
}

.homepage-genre-button {
	width: 100px;
	height: 100px;
	border-radius: 50px;
	border: none;
}

.homepage-genre-name {
	font-size: 14px;
}

/* Leave your contacts */

.homepage-contacts-box {
	background-color: #fff7ec;
}

.homepage-leave-contacts {
	/* background-color: lightpink; */
	padding-bottom: 40px;
}

.homepage-leave-contacts-title {
	font-weight: 700;
	font-size: 28px;
	/* background-color: aliceblue; */
	margin: 0;
	padding-top: 50px;
	margin-left: 5%;
	color: #452724;
}

.homepage-contacts {
	width: 85%;
	height: 27px;
	margin-left: 5%;
	margin-top: 25px;
	padding: 20px 10px 20px 10px;
	font-size: 20px;
	border: none;
	background-color: transparent;
	border-bottom: 2px solid grey;
	transition: transform 0.2s ease;
	font-size: 16px;
}
.homepage-contacts:focus {
	border-bottom: 3px solid #452724;
	transform: translateY(-1px);
}

.homepage-contacts-occupation {
	width: 85%;
	height: 27px;
	margin-left: 5%;
	margin-top: 15px;
	padding-left: 7px;
	font-size: 13px;
	border: 1px solid #4d4d4d;
}

.homepage-contacts-image-box {
	/* background-color: darkkhaki; */
	text-align: center;
}

.homepage-contacts-submit-button {
	width: 120px;
	height: 35px;
	margin-left: 5%;
	margin-top: 40px;
	border-radius: 10px;
	margin-bottom: 10px;
	background-color: #ffce8a;
	color: #452724;
	font-weight: 500;
	font-size: 14px;
	border: none;
}

.homepage-contacts-submit-button:hover {
	background-color: black;
}

.homepage-message {
	font-size: 16px;
	overflow-wrap: break-word;
	word-wrap: normal;
	resize: none;
	word-break: break-all;
}

.homepage-signup-message {
	text-align: center;
	background-color: var(--main-color);
	padding: 10px;
	border-radius: 5px;
	filter: opacity(0.7);
	color: black;
}
.homepage-signup-message-error {
	text-align: center;
	background-color: rgb(255, 101, 101);
	padding: 10px;
	border-radius: 5px;
	filter: opacity(0.7);
	color: black;
}

.homepage-signup-message-container {
	position: fixed;
	top: 100px;
	width: 100%;
	z-index: 1000;
	text-align: center;
	display: flex;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
}

.homepage-contacts-submit-button-disabled {
	width: 100px;
	height: 30px;
	margin-left: 5%;
	margin-top: 15px;
	border-radius: 10px;
	background-color: #808080;
	color: white;
	font-weight: 500;
	font-size: 13px;
	border: none;
}
