
@keyframes scaling{
    0%, 100%{
      transform: scale(0.2);
      background-color: #30FFB7;
    }
    40%{
      transform: scale(1) translateY(10px);
      background-color: #07DEFF;
    }
    50%{
      transform: scale(1);
      background-color: #0761FF;
    }
    60%{
        transform: scale(1) translateY(-10px) ;
        background-color: #07DEFF;
    }
  }

  .main-div{
      height: 100vh;
      display: flex;
      justify-content: center;
      align-content: center;
  }

  .loading-screen{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      padding-top: 25%;
  }
  
  .photo-loading{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    position: relative;
    width: 100%;
    height: 25vh;
    padding-top: 300px;
    padding-bottom: 125px;
}

.profile-loading{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  position: relative;
  width: 100%;
}

  .loading-dots {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 20px;
      width: 20px;
      border-radius:50%;
      transform: scale(0);
      background-color:red;
      animation: scaling 1s ease-in-out infinite;
      margin: 0.5rem;
  }
  
  .loading-dots:nth-child(0){
    animation-delay:0s;
  }
  .loading-dots:nth-child(1){
    animation-delay:0.05s;
  }
  .loading-dots:nth-child(2){
    animation-delay:0.09s;
  }
  .loading-dots:nth-child(3){
    animation-delay:0.12s;
  }
  .loading-dots:nth-child(4){
    animation-delay:0.14s;
  }
  .loading-dots:nth-child(5){
    animation-delay:0.15s;
  }
  
