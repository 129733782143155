.book-page-container{
    width: 90vw;
    margin: 5%;
}

.book-overview-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    width: 100%;
    align-content: center;
    align-items: center;

}

.back-to-search-div{
    height: 75px;
    user-select: none;
}

.book-summary-photo-container{
    margin: 0 5% 0 5%;
}

.book-summary-container{
    width: 80%;
    margin: 0 10% 0 10%;
    display: flex;
    flex-direction: row;
    justify-content: center ;
}

.book-summary-container img{
    height: 300px;
    width: 200px;
}

.book-summary-info{
    padding: 0 20px 0 20px;
}

.book-summary-book-title{
    font-size: 24pt;
    font-weight: 600;
    padding-right: 10px;
}

.book-summary-book-year-published{
    font-style: italic;
}

.book-summary-ratings{
    padding: 5px 0 5px 0 ;
    font-size: 14pt;
}

.book-summary-ratings label{
    font-size: 10pt;
    font-style: italic;
    padding-left: 10px;
}

.book-summary-genre{
    padding: 20px 0 20px 0;
    
}

.book-summary-genre label{
    background-color: var(--secondary-color);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-size: 8pt;
    font-weight: 600;
}

.book-summary-action-bar{
    width: 200px;
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 0;
    align-items: center;
}

.book-summary-action-bar button{
    padding: 5px 10px 5px 10px;
    width: 175px;
    border-radius: 5px;
    font-size: 8pt;
    font-weight: 500;
    border: none;
}

.add-to-favourites{
    background-color: var(--main-color);
}

.remove-from-favourites{
    background-color:rgb(190, 92, 92)
}