.class-files-table-container{
    overflow:hidden;
    border: none;
    table-layout:fixed;
    width:100%;
}


.class-files-table-headers{
    background-color: var(--secondary-color);
    border-bottom: 1.5px solid darkgrey;
}

.class-files-column-header{
    padding: 1rem 1rem 0 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.class-files-column-header-sortable{
    padding: 1rem 1rem 0 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    user-select: none;
    cursor: pointer;
    overflow:hidden;
    white-space:nowrap;
}

.class-files-column-header-action{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.class-files-column-header-sortable:hover{
    background-color: rgb(231, 230, 230);
}

.class-files-data-field{
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: center;
    font-size: 1.5vh;
    overflow:hidden;
}


.class-files-data-row{
    border-bottom: 0.5px solid rgb(218, 218, 218);
    background-color: var(--secondary-color);
    cursor: pointer;
}

.class-files-data-row:hover{
    background-color: white;
}

.class-files-action-bar{
    width: 100%;
    background-color: var(--secondary-color);
    border-bottom: 1px solid rgb(219, 219, 219);
}

.class-files-action-bar button{
    padding: 2.5px 20px 2.5px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    font-size: 12pt;
    color: white;
    background: rgb(116, 116, 116);
}

.class-files-action-bar button:hover{
    filter: brightness(1.2)
}

.class-files-new-folder-input{
    margin: 5% 5% 5% 5%;
    border-radius: 5px;
    border: 1px solid rgb(116, 116, 116);
    padding: 10px; 
}

.class-files-folder-tree{
    font-weight: 600;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(219, 219, 219);
    margin-bottom: 15px;
}

.class-files-tree-object{
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    user-select: none;
}

.class-files-tree-object:hover{
    background-color: var(--secondary-color);

}