.class-list-table-container{
    overflow:hidden;
    border: none;
    table-layout:fixed;
    width:100%;
}



.class-list-table-headers{
    background-color: var(--secondary-color);
    border-bottom: 1.5px solid darkgrey;
}

.column-header{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.column-header-sortable{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    user-select: none;
    cursor: pointer;
    overflow:hidden;
    white-space:nowrap;
}

.column-header-action{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.column-header-sortable:hover{
    background-color: rgb(231, 230, 230);
}

.data-field{
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: center;
    font-size: 1.5vh;
    overflow:hidden;
}

.data-field-action{
    padding: 1rem;
    text-align: center;
    font-size: 2vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    height: 100%;
}

.data-row{
    border-bottom: 0.5px solid rgb(218, 218, 218);
    background-color: var(--secondary-color);
}

.data-row:hover{
    background-color: white;
}

.user-roles-container{
    display:flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.user-role-button{
    display:flex;
    background-color: rgb(202, 202, 202);
    padding: 2px 3px 2px 3px;
    margin: 1px 2px 1px 2px;
    border-radius: 5px;
    font-size: 1vh;
    font-weight: 600;
    min-width: 2.5rem;
    align-content:center;
    justify-content: center;
    user-select: none;
}

.user-role-button:hover{
    filter: brightness(1.2);
}

.user-role-button-special{
    display:flex;
    background-color: var(--third-color);
    padding: 2px 3px 2px 3px;
    margin: 1px 2px 1px 2px;
    border-radius: 5px;
    font-size: 1vh;
    font-weight: 600;
    min-width: 2.5rem;
    align-content:center;
    justify-content: center;
    user-select: none;
}

.user-role-button-special:hover{
    filter: brightness(1.2);
}

.classroom-list-action-button{
    cursor: pointer;
    user-select:none;
    color: rgb(158, 158, 158);
    font-weight: 500;
    width: 15%;
}

.classroom-list-action-menu{
    display: flex;
    flex-direction: column;
    background: var(--main-color);
    position: absolute;
    right: 3vh;
    margin-top: 5vh;
    border-radius: 5px;
}


.classroom-list-action-menu-item{
    padding: 0.5rem;
    font-size: 0.75rem;
}

.classroom-list-action-menu-item:hover{
    background-color: var(--third-color);
    cursor: pointer;
}
.classroom-list-action-menu-item:first-child:hover{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.classroom-list-action-menu-item:last-child:hover{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.no-classes-assigned{
    width: 100%;
    background-color:rgb(252, 252, 252);
    text-align: center;
    padding: 15% 15% 25% 15%;
    border-radius: 5px;
}

.no-classes-assigned label{
    font-size: 24pt;
    font-style: italic;
    color: rgb(73, 73, 73);
}
.no-classes-assigned p{
    font-size: 18pt;
    font-style: italic;
    color: grey;
}
.no-classes-assigned h2{
    font-size: 48pt;
}