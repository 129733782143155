.box {
	width: 100%;
	min-width: 300px;
}

.box2 {
	margin-left: auto;
	margin-right: auto;
}

.book-item-search-new-release-container{
	display:flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}
.book-item-search-new-release{
	width: 175px;
	height:250px;
	align-content: center;
	align-self: center;
	align-items: center;
	text-align: center;
}

.search {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
	margin: 100px 0 100px 0;
}

.search-bar-inner {
	position: relative;
	width: 75%;
}

.search-icon {
	position: absolute;
	color: gray;
	left: 10px;
	top: 15px;
	height: 20px;
	width: 20px;
	transition: transform 0.1s ease-in-out;
}

.search-bar {
	display: block;
	width: 100%;
	height: 50px;
	font-size: 12px;
	padding-left: 40px;
	font-size: 16pt;
	border: none;
	border-bottom: 3px solid rgb(204, 204, 204);
	color: gray;
	font-weight: 500;
}

.search-bar:focus {
	border-bottom: 3px solid rgb(63, 63, 63);
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.search-bar:focus ~ .search-icon {
	color: rgb(63, 63, 63);
	transform: scale(1.2);
}

.search-bar:focus ~ .search-bar-enter {
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.search-bar-enter {
	position: absolute;
	right: 15px;
	top: 20px;
	height: 20px;
	width: 75px;
	min-width: 75px;
	color: rgb(204, 204, 204);
	user-select: none;
}

.header {
	display: block;
	font-size: 35px;
	font-weight: 700;
	width: 100%;
	color: #452724;
}
.header p {
	width: 100%;
	text-align: center;
}

.header-for-new-release {
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	align-self: center;
	color: #452724;
	padding: 50px 0 15px 0 ;
}

.sort-description {
	margin-bottom: 0px;
	font-size: 12px;
}

.sort-dropdown {
	margin-top: 0px;
	border-radius: 6px;
	font-size: 12px;
}

.new-release-box {
	width: 100%;
	background-color: #fff7ec;
	margin: 75px 0 75px 0;
}

.book-cover {
	width: 120px;
	height: 190px;
	border-radius: 3px;
	margin-bottom: 0px;
	user-select: none;
	margin-left: auto;
	margin-right: auto;
	position: inherit;
}

.results-book-cover {
	width: 130px;
	height: 206px;
	border-radius: 3px;
	margin-bottom: 0px;
	user-select: none;
	margin-left: auto;
	margin-right: auto;
	position: inherit;
	padding: 5px;
}

.col-3 {
	text-align: center;
}

.book-title {
	padding: 0;
	margin-top: 5px;
	font-size: 14px;
}

.filter-box {
	/* background-color: #e5e5e5; */
	width: 700px;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	padding: 22px;
	border-radius: 15px;
}

.sort-bar {
	margin-top: 13px;
}

.rowC {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.results-image {
	width: 130px;
	height: 220px;
	/* background-color: red; */
	text-align: center;
	position: relative;
	margin-left: 25px;
}

.results-description {
	height: 220px;
	/* background-color: #89c8e6; */
	margin-left: 15px;
	width: 100%;
}

.results-box {
	width: 700px;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	width: 75%;
}

.results-title {
	/* background-color: rosybrown; */
	font-size: 20px;
	font-weight: 700;
	bottom: 0;
	width: 100%;
}

.results-year {
	/* background-color: seashell; */
	font-size: 11px;
	bottom: 0;
	width: 100px;
	font-style: italic;
	padding-top: 9px;
	margin-left: 6px;
}

.box3 {
	height: 30px;
	width: 100%;
	overflow-x: auto;
	/* background-color: wheat; */
}

.author-name {
	/* background-color: rgb(240, 236, 35); */
	font-size: 12px;
	margin-bottom: -17px;
}

.rating {
	/* background-color: lightgreen; */
	margin-top: 0px;
}

.rating-number {
	/* background-color: lightyellow; */
	font-size: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: italic;
	margin-left: 5px;
	padding-top: 5px;
}

.save-button {
	font-size: 10px;
	border-radius: 9px;
	border: 1px solid #525252;
	padding: 3px 9px 3px 9px;
	margin-top: 10px;
	background: white;
	transition: 0.2s;
	color: #525252;
}

.save-button:hover {
	background-color: #525252;
	color: white;
}

.favourites-button {
	border-radius: 100px;
	border: 1px solid black;
	background: white 50%;
	padding: 0px 5px 0px 5px;
	opacity: 0.7;
	top: 77%;
	left: 71%;
	position: absolute;
}

.book-description {
	font-size: 10pt;
	max-height: 60%;
	overflow-y: auto;
}




.search-info-box{
	margin-left: 15%;
}
.search-filter-bar-container {
	width: 100%;
	padding: 15px 15% 15px 15%;
	background-color: #243245;
	margin: 10px 0 35px 0;
}

.genre-not-selected {
	border: none;
	border-radius: 3px;
	padding: 5px 10px 5px 10px;
	margin: 10px;
	background-color: #fff7ec;
	color: #243245;
	font-weight: 600;
	font-size: 12px;
}

.search-filter-bar-container button:hover {
	background-color: #ffce8a;
	color: #243245;
}

.genre-selected {
	border: none;
	border-radius: 3px;
	padding: 5px 10px 5px 10px;
	margin: 10px;
	background-color: #ffce8a;
	color: #243245;
	font-weight: 600;
	font-size: 12px;
}

.search-page-buttons-bar {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
	margin: 20px 0 20px 0;
}

.search-page-buttons {
	border: none;
	padding: 5px 10px 5px 10px;
	margin: 3px;
	background-color: #fff7ec;
}
.search-page-buttons-active {
	border: none;
	padding: 5px 10px 5px 10px;
	margin: 3px;
	background-color: #ffce8a;
	font-weight: 500;
}

.search-page-buttons:hover {
	background-color: #ffce8a;
}
