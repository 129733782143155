.about-us-title-image {
	width: 100%;
}

.about-us-title-text {
	font-weight: 700;
	font-size: 35px;
	margin-top: -80px;
	margin-bottom: 90px;
	margin-left: 10%;
	color: white;
}

.abous-us-main-page-container {
	min-width: 400px;
}

.about-us-description {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.about-us-values-container {
	margin-top: 70px;
	background-color: #243245;
	padding-bottom: 90px;
}

.about-us-values-title {
	color: #ffce8a;
	font-weight: 700;
	font-size: 35px;
	margin-left: 10%;
	padding-top: 80px;
	padding-bottom: 40px;
}

.about-us-values-container-2 {
	width: 90%;
	/* background-color: lightgreen; */
	margin-left: 5%;
}

.about-us-team-container {
	background-color: #fff7ec;
	padding-bottom: 70px;
}

@media only screen and (min-width: 914px) {
	.about-us-team-inner-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		align-items: center;
		justify-items: center;
		margin-left: 50px;
		margin-right: 50px;
	}
}

@media only screen and (max-width: 914px) and (min-width: 480px) {
	.about-us-team-inner-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		justify-items: center;
		margin-left: 50px;
		margin-right: 50px;
	}
}

@media only screen and (max-width: 480px) {
	.about-us-team-inner-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		justify-items: center;
	}
}

.about-us-team-title {
	font-weight: 700;
	font-size: 35px;
	margin-left: 10%;
	padding-top: 80px;
	padding-bottom: 40px;
	color: #3c2a2d;
}

.about-us-team-profile-photo {
	width: 150px;
	height: 150px;
}

.about-us-team-profile-container {
	text-align: center;
	width: 200px;
	height: 250px;
}

.about-us-team-member-name {
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 0;
	color: #3c2a2d;
}

.about-us-team-member-title {
	font-size: 14px;
	margin-top: -5px;
	margin-bottom: 0;
	color: #3c2a2d;
}

p span {
	display: block;
}

.values-content {
	background-color: thistle;
	width: 50px;
	font-size: 20px;
}
