.class-library-title-select{
    width: 100%;
}

.class-library-main-content-container{
    width: 100%;
    height: 100%;
    padding: 20px 10px 20px 10px;
    margin: 20px 0 20px 0;
    background-color: var(--secondary-color);
    position:relative;
}

.class-library-book-container{
    position: relative;
    border-radius: 5px;
    margin: 0px;
}

.class-library-book-container-editing{
    position: relative;
    border-radius: 5px;
    margin: 0px;
    background-color: var(--secondary-color);
}

.class-library-book-container-editing:hover{
    position: relative;
    border-radius: 5px;
    border: 1px dotted rgb(168, 168, 168) ;
    z-index: 3;
}

.class-library-book-a-tag{
    width: 125px;
    margin: 5px 30px 5px 30px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: center;
    align-items: center;
    transition: transform 0.1s ease-in-out
}

.class-library-delete-book{
    position: absolute;
    border: none;
    background-color: black;
    color: white;
    border-radius: 50%;
    left: 15px;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 9pt;
}

.class-library-book-container button:hover{
    color: black;
    background-color: white;
}


.class-library-book-container a:hover{
    transform: translateY(-1px);
    font-weight: 500;
}

.class-library-book-cover{
    width: 150px;
    height: 225px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    user-select: none;
    position: relative;
}

.class-library-book-cover img{
    width: 150px;
    height: 225px;
    user-select: none;
}

.class-library-book-title{
    text-align: left;
    vertical-align: center;
    font-size: 11pt;
    width: 150px;
    font-weight: 600;
}

.class-library-book-author{
    text-align: left;
    vertical-align: center;
    font-size: 9pt;
    width: 150px;
    font-weight: 300;
}

.class-library-action-bar{
    width: 100%;
    display: flex;
    position: relative;
}


.class-library-sortby{
    padding-left: 15px;
}

.class-library-bookshelf-container{
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
}

.class-library-title{
    font-size: 16pt;
    font-weight: 600;
    margin: 15px;
    display: flex;
    align-items: center;
}

.class-library-title-input{
    font-size: 16pt;
    font-weight: 600;
    margin: 15px;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid rgb(233, 233, 233);
    transform: translateY(1px);
    transition: transform 0.5s cubic-bezier(0.1, 1.01, 1, 1);
    width: 50%;
}

.class-library-title-input:focus{
    transform: translateY(0px);
    border-bottom: 2px solid rgb(168, 168, 168);
}



.class-library-add-book-input input{
    font-weight: 600;
    margin: 15px 0 15px 15px;
    padding: 5px;
    background-color: transparent;
    border: 2px solid rgb(168, 168, 168);
    width: 25%;
}

.class-library-add-book-input button{
    border: 2px solid rgb(168, 168, 168);
    background-color: rgb(168, 168, 168);
    width: 35px;
    margin: 15px 0 15px 0;
    padding: 5px;
}

.class-library-add-book-input button:hover{
    filter: brightness(1.2);
}

.class-library-edit{
    border: none;
    margin-left: 15px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    background-color: rgb(168, 168, 168);
    font-size: 10pt;
}

.class-library-edit:hover{
    filter: brightness(1.2);
}

.class-library-action-save-cancel{
    margin: 30px 15px 10px 20px; 
    border-top: 1px solid rgb(214, 213, 213);
}
.class-library-action-save-cancel button{
    margin: 10px 10px 0 0;
    border: none;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
}

.class-library-action-save-cancel button:hover{
    filter: brightness(1.2)
}

.class-library-save{
    background-color: var(--main-color);
}

.class-library-delete{
    background-color: rgb(255, 85, 85);
}
.class-library-edit{
    background-color: rgb(168, 168, 168);
}

.class-library-dragging{
    opacity: 0.2;
}