.ar-assignments-box {
	width: 100%;
	margin: 0;
}

/* Screen smaller than 1050 */

@media only screen and (max-width: 1050px) {
	.ar-assignments-box2 {
		width: 90%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		background-color: #ececec;
		position: relative;
		padding-bottom: 75px;
		top: 10px;
	}
}

/* Screen larger than 1050 */

@media only screen and (min-width: 1050px) {
	.ar-assignments-box2 {
		width: 75%;
		right: 0;
		margin-left: 20.5%;
		margin-bottom: 40px;
		position: relative;
		background-color: #ececec;
		padding-bottom: 75px;
		top: 10px;
	}
}

/* Date Posted and Due Date */

.a-details-date-posted {
	margin-left: 35px;
	margin-top: 10px;
}

.a-details-date-posted-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-date-posted-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-details-due-date {
	margin-top: 10px;
	margin-left: 60px;
}

.a-details-due-date-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-due-date-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-due-date-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
}

.a-edit-icon {
	margin-top: -5px;
}

.a-assignment-details-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
	margin-top: 10px;
}

.a-assignment-details-label {
	font-weight: 700;
	font-size: 15px;
	margin-left: 35px;
	margin-top: 10px;
}

.a-assignment-details-tetxt {
	margin-top: -15px;
	font-size: 15px;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 35px;
}

.ar-assignment-title {
	padding-top: 35px;
	margin-left: 35px;
	font-size: 20px;
}

.ar-student-name {
	margin-left: 35px;
	font-size: 16px;
	font-weight: 700;
	margin-top: -20px;
}

.ar-submitted-date {
	margin-left: 15px;
	font-size: 13px;
	font-weight: 300;
	margin-top: -17px;
	font-style: italic;
}

.ar-preview-label {
	margin-left: 35px;
	font-size: 18px;
	margin-bottom: 0;
}

.ar-preview-box {
	margin-left: 35px;
	margin-top: 0px;
	margin-bottom: 30px;
	width: 92%;
	min-height: 500px;
	max-height: 700px;
	border-radius: 15px;
	border: 1px solid black;
	padding: 10px;
}

.ar-score-label {
	margin-left: 35px;
	font-size: 18px;
	margin-bottom: 0;
}

.ar-score-input {
	margin-left: 5px;
	height: 25px;
	width: 50px;
	border-radius: 8px;
	border: 1px solid black;
	text-align: center;
	font-size: 15px;
}

.ar-score-100 {
	margin-left: 7px;
	color: #4b4b4b;
	font-size: 15px;
	margin-top: 1.5px;
}

.ar-feedback-label {
	margin-left: 35px;
	font-size: 18px;
	margin-bottom: 0;
	margin-top: 10px;
}
.ar-feedback-input {
	margin-left: 35px;
	margin-top: 0px;
	margin-bottom: 30px;
	width: 92%;
	min-height: 200px;
	max-height: 500px;
	border-radius: 15px;
	border: 1px solid black;
	padding: 10px;
}

.ar-cancel-button {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: #666666;
	color: white;
	font-weight: 600;
	border: none;
	right: 365px;
}

.ar-cancel-button:hover {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: black;
	color: white;
	font-weight: 600;
	border: none;
	right: 365px;
}

.ar-save-button {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: #666666;
	color: white;
	font-weight: 600;
	border: none;
	right: 200px;
}

.ar-save-button:hover {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: black;
	color: white;
	font-weight: 600;
	border: none;
	right: 200px;
}

.ar-submit-button {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: #666666;
	color: white;
	font-weight: 600;
	border: none;
	right: 35px;
}

.ar-submit-button:hover {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: black;
	color: white;
	font-weight: 600;
	border: none;
	right: 35px;
}
