.assignments-box {
	width: 100%;
	margin: 0;
}

/* Screen smaller than 1050 */

@media only screen and (max-width: 1050px) {
	.assignments-box2 {
		width: 90%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 40px;
		position: relative;
	}

	.a-bloc-tabs {
		display: flex;
	}

	.a-add-new-button {
		background-color: transparent;
		width: 85px;
		border: none;
		margin-bottom: 10px;
		font-size: 13px;
	}

	.a-edit-button {
		background-color: transparent;
		width: 40px;
		border: none;
		margin-bottom: 10px;
		font-size: 13px;
		position: absolute;
		right: 0;
	}

	.a-add-icon {
		margin-bottom: 2px;
		margin-left: -2px;
		margin-right: 3px;
	}
}

/* Screen larger than 1050 */

@media only screen and (min-width: 1050px) {
	.assignments-box2 {
		width: 75%;
		right: 0;
		margin-left: 20.5%;
		margin-bottom: 40px;
		position: relative;
	}

	.a-bloc-tabs {
		display: flex;
		margin-top: 0px;
	}

	.a-add-new-button {
		background-color: transparent;
		width: 85px;
		border: none;
		margin-bottom: 10px;
		font-size: 13px;
		margin-top: 10px;
	}

	.a-edit-button {
		background-color: transparent;
		width: 40px;
		border: none;
		margin-bottom: 19px;
		font-size: 13px;
		position: absolute;
		right: 0;
		margin-top: 10px;
	}

	.a-add-icon {
		margin-bottom: 2px;
		margin-left: -2px;
		margin-right: 3px;
	}
}

/* Tab formatting */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background: #fff;
}

.a-container {
	display: flex;
	flex-direction: column;
	position: relative;
	word-break: break-all;
}

.a-tabs {
	padding: 15px;
	text-align: center;
	width: 50%;
	background: #fdfdfd;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	font-weight: 200;
	color: #656565;
}

.a-active-tabs {
	background: #ececec;
	font-weight: 700;
	color: black;
}

.a-active-tabs::before {
	content: '';
	display: block;
	position: absolute;
	top: -5px;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + 2px);
	height: 5px;
}

.a-content-tabs {
	flex-grow: 1;
}

.a-content {
	background: #ececec;
	padding: 20px;
	width: 100%;
	height: 100%;
	display: none;
}

.a-active-content {
	display: block;
	min-height: 500px;
}

/* Content Formatting */

.a-container2 {
	position: relative;
}

.a-assignment-name-header {
	margin-left: 10px;
	font-weight: 600;
	width: 39%;
}

.a-date-posted-header {
	margin-left: 3%;
	font-weight: 600;
	width: 20%;
}
.a-due-date-header {
	margin-right: 19%;
	right: 0;
	position: absolute;
	font-weight: 600;
	width: 17%;
}

.a-submission-header {
	right: 3%;
	position: absolute;
	font-weight: 600;
	width: 13%;
}

.a-assignment-name-content {
	margin-left: 10px;
	color: #3e3e3e;
	font-weight: 300;
	width: 39%;
	background-color: transparent;
	text-align: left;
	border: none;
}

.a-date-posted-content {
	margin-left: 3%;
	color: #3e3e3e;
	font-weight: 300;
	width: 20%;
}
.a-due-date-content {
	margin-right: 19%;
	right: 0;
	position: absolute;
	color: #3e3e3e;
	font-weight: 300;
	width: 17%;
}

.a-submission-content {
	right: 3%;
	position: absolute;
	color: #3e3e3e;
	font-weight: 300;
	width: 13%;
	text-align: center;
}

.a-content-margin-top {
	margin-top: 30px;
}

.a-content-margin-top hr {
	margin-top: 10px;
}
