@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



.main-profile-content{
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: relative;
}

.cover-photo{
    width: 100%;
    background-color: #F8F8F8;
    height: 275px;
    position:absolute;
}

.profile-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.profile-picture-div{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    top: 100px;
    padding-bottom: 100px;
}

.profile-image{
    height:25vh;
    width:25vh;
    border-radius: 50%;
}


.profile-info-div{
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    justify-items: center;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(230, 230, 230);
}

.display-name{
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 4vh;
    font-family: 'Montserrat';
    font-weight: 500;
    color: rgb(30, 30, 30);
}

.email{
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 1.75vh;
    font-family: 'Montserrat';
    font-weight: 300;
    color: rgb(150, 150, 150);
    padding: 0px 5px 0px 5px;

}

.class-teacher{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    font-size: 2vh;
    font-family: 'Montserrat';
    font-weight: 300;
    margin-right: 7.5%;
}

.bold-stats{
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: 500;
    width: 100%;
}

.profile-button-div {
    position: absolute;
    top: 0vh;
    right: 3vw;
}

.profile-button {
    display: flex;
    font-size: 1.75vh;
    border-width: 0px;
    outline-width: 0px;
    background-color: whitesmoke;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 10px;
    justify-content: center;
    align-content: center;
    width:100%;
}

.profile-button:hover{
    border-color: rgb(206, 206, 206);
    background: rgb(110, 110, 110);;
}

.other-profile-info-div{      
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    width: 200px;
    align-content: center;
    justify-content: center;
}

.biography{
    width: 95%;
    padding: 1% 5% 2.5% 5%;
    overflow:hidden;
    max-height: 100px;
    color:rgb(100, 100, 100);
    text-align: justify;
    text-justify: inter-word;

}

.profile-stats{
    display: flex;
    flex-direction: row;
    align-content: center;
    position: relative;
    justify-items: center;
    justify-content: center;
    align-items: center;
    padding: 0%;
    width: 100%;
    border-bottom: 1px solid rgb(230, 230, 230);
}

.stat-container{
    display:flex;
    flex-direction: row;
    width: 100%;
    height: 125px;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-left: 1px solid rgb(230, 230, 230);
    border-right: 1px solid rgb(230, 230, 230);
    padding: 5%;
    overflow: hidden;
    user-select: none;
}

.stat-card{
    display:flex;
    flex-direction: column;
    width: 100%;
    height: 125px;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    animation: stats-bounce-in 1s cubic-bezier(0.71, 0.03, 0, 0.99) 0.5s forwards;
    transform: translateY(150px);
    user-select: none;
}

@keyframes stats-bounce-in {
    from{transform: translateY(150px);}
    to{transform: translateY(0px);}
}

.stat-container:first-child{
    border-left: 0px;
    border-right: 1px solid rgb(230, 230, 230);
}
.stat-container:last-child{
    border-right: 0px;
    border-left: 1px solid rgb(230, 230, 230);
}

.stat-label{
    font-weight: 600;
    font-size: 7vh;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    user-select: none;
}
.stat-value{
    font-weight: 600;
    font-size: 2vh;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    user-select: none;
}

.stat-container:hover{
    background-color: var(--secondary-color);
}
.stat-container:hover .stat-explore{
    visibility:visible;
    opacity: 1;
    transform: rotate(90deg);
}

.stat-explore{
    width: 10px;
    font-weight: 600;
    font-size: 3vh;
    visibility:hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.05s, transform 0.1s ease-in;
}


.bookshelf-container{
    margin-top: 7.5px;
    width: 100%;
    align-content: center;
    padding: 2%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 7.5px;
    overflow: hidden;
}

.bookshelf-container:first-child{
    margin-top: 15px;
    width: 100%;
    align-content: center;
    padding: 2%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 7.5px;
    overflow: hidden;
}

.bookshelf-container:last-child{
    margin-top: 7,5px;
    width: 100%;
    align-content: center;
    padding: 2%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
}

.bookshelf-content{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    animation: books-bounce-in 1s cubic-bezier(0.71, 0.03, 0, 0.99) 0.8s forwards;
    transform: translateX(-1000px);
    min-height: 200px;


}

@keyframes books-bounce-in {
    from{transform: translateX(-1000px);}
    to{transform: translateX(0px);}
}

.bookshelf-title{
    font-weight: 700;
    font-size: 2vh;
    padding-bottom: 2%;
    user-select: none;
}


.profile-book-container a{
    width: 125px;
    margin: 5px 30px 5px 30px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: center;
    align-items: center;
    transition: transform 0.1s ease-in-out
}


.profile-book-container a:hover{
    transform: translateY(-1px);
    font-weight: 500;
}

.profile-book-cover{
    width: 100px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    user-select: none;
}

.profile-book-cover img{
    width: 100px;
    height: 150px;
    user-select: none;
}

.profile-book-title{
    text-align: center;
    vertical-align: center;
    width: 125px;
}

