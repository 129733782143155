.leaderboard-box {
	width: 100%;
}
@media only screen and (max-width: 1050px) {
	.leaderboard-box2 {
		width: 95%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}
@media only screen and (min-width: 1050px) {
	.leaderboard-box2 {
		width: 84%;
		right: 0;
		margin-left: 16%;
	}
}

.filter-section {
	/* background-color: salmon; */
	position: relative;
	min-height: 80px;
}

.filter {
	width: fit-content;
	position: absolute;
	right: 0;
	margin: 30px 30px 0px 0px;
}

.filter-description {
	margin-bottom: 0px;
	font-size: 12px;
}

.sort-dropdown {
	margin-top: 0px;
	border-radius: 6px;
	font-size: 12px;
}

.col-4 {
	text-align: center;
}

.top-three-section {
	margin-bottom: 20px;
}

/* Second Place CSS */

.second-place-profile-picture {
	width: 90px;
	/* border: black 1px solid; */
	border-radius: 100px;
}

.second-place-name {
	font-weight: 600;
	margin-top: 7px;
	margin-bottom: -3px;
}

.second-place-points {
	font-size: 14px;
	font-weight: 600;
	color: #585858;
}

.second {
	margin: 0px;
	width: fit-content;
	position: absolute;
	font-weight: 700;
	font-size: 24px;
}
.second-position {
	position: relative;
	/* background-color: skyblue; */
	width: 180px;
	margin-left: auto;
	margin-right: auto;
}

/* First Place CSS */

.first-place-profile-picture {
	width: 110px;
	/* border: black 1px solid; */
	border-radius: 100px;
}

.first-place-name {
	font-weight: 600;
	margin-top: 7px;
	margin-bottom: -3px;
	font-size: 18px;
}

.first-place-points {
	font-size: 16px;
	font-weight: 600;
	color: #585858;
}

.first {
	margin: 0px;
	width: fit-content;
	position: absolute;
	font-weight: 700;
	font-size: 30px;
}
.first-position {
	position: relative;
	/* background-color: skyblue; */
	width: 190px;
	margin-left: auto;
	margin-right: auto;
}

/* Third Place CSS */

.third-place-profile-picture {
	width: 80px;
	/* border: black 1px solid; */
	border-radius: 100px;
}

.third-place-name {
	font-weight: 600;
	margin-top: 7px;
	margin-bottom: -3px;
	font-size: 15px;
}

.third-place-points {
	font-size: 13px;
	font-weight: 600;
	color: #585858;
}

.third {
	margin: 0px;
	width: fit-content;
	position: absolute;
	font-weight: 700;
	font-size: 20px;
}
.third-position {
	position: relative;
	width: 150px;
	margin-left: auto;
	margin-right: auto;
}

.profile-picture {
	width: 43px;
	height: 43px;
	/* border: black 1px solid; */
	border-radius: 100px;
	margin-left: 15px;
	margin-right: 20px;
}

.even-fourth {
	height: 70px;
	background-color: #f4f4f4;
	position: relative;
}

.odd-fourth {
	height: 70px;
	background-color: white;
	position: relative;
}

.leaderboard-rank {
	margin-right: 10px;
	font-size: 30px;
	margin-top: 15px;
	margin-left: 30px;
	font-weight: 600;
}

.leaderboard-student-name {
	font-weight: 600;
	font-size: 20px;
	margin-top: 15px;
}

.leaderboard-points {
	font-weight: 600;
	font-size: 18px;
	margin-top: 16px;
	right: 30px;
	color: #464646;
	position: absolute;
}
