@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

@-webkit-keyframes scaling{
    0%, 100%{
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      background-color: #30FFB7;
    }
    40%{
      -webkit-transform: scale(1) translateY(10px);
              transform: scale(1) translateY(10px);
      background-color: #07DEFF;
    }
    50%{
      -webkit-transform: scale(1);
              transform: scale(1);
      background-color: #0761FF;
    }
    60%{
        -webkit-transform: scale(1) translateY(-10px) ;
                transform: scale(1) translateY(-10px) ;
        background-color: #07DEFF;
    }
  }

  @keyframes scaling{
    0%, 100%{
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      background-color: #30FFB7;
    }
    40%{
      -webkit-transform: scale(1) translateY(10px);
              transform: scale(1) translateY(10px);
      background-color: #07DEFF;
    }
    50%{
      -webkit-transform: scale(1);
              transform: scale(1);
      background-color: #0761FF;
    }
    60%{
        -webkit-transform: scale(1) translateY(-10px) ;
                transform: scale(1) translateY(-10px) ;
        background-color: #07DEFF;
    }
  }

  .main-div{
      height: 100vh;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-content: center;
              align-content: center;
  }

  .loading-screen{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-content: center;
              align-content: center;
      padding-top: 25%;
  }
  
  .photo-loading{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    position: relative;
    width: 100%;
    height: 25vh;
    padding-top: 300px;
    padding-bottom: 125px;
}

.profile-loading{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  position: relative;
  width: 100%;
}

  .loading-dots {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-content: center;
              align-content: center;
      height: 20px;
      width: 20px;
      border-radius:50%;
      -webkit-transform: scale(0);
              transform: scale(0);
      background-color:red;
      -webkit-animation: scaling 1s ease-in-out infinite;
              animation: scaling 1s ease-in-out infinite;
      margin: 0.5rem;
  }
  
  .loading-dots:nth-child(0){
    -webkit-animation-delay:0s;
            animation-delay:0s;
  }
  .loading-dots:nth-child(1){
    -webkit-animation-delay:0.05s;
            animation-delay:0.05s;
  }
  .loading-dots:nth-child(2){
    -webkit-animation-delay:0.09s;
            animation-delay:0.09s;
  }
  .loading-dots:nth-child(3){
    -webkit-animation-delay:0.12s;
            animation-delay:0.12s;
  }
  .loading-dots:nth-child(4){
    -webkit-animation-delay:0.14s;
            animation-delay:0.14s;
  }
  .loading-dots:nth-child(5){
    -webkit-animation-delay:0.15s;
            animation-delay:0.15s;
  }
  

.main-footer {
	color: #ffce8a;
	background-color: #243245;
	padding: 3em;
	position: relative;
	bottom: 0;
	width: 100%;
	min-width: 400px;
}

.footer-header {
	font-size: 18px;
	text-align: left;
	font-weight: 600;
}

.footer-item {
	font-size: 12px;
	text-align: left;
	color: #ffce8a;
	margin-top: 5px;
}

.icon-button-1 {
	position: static;
	padding: none;
	margin-left: 15px;
	background-color: transparent;
	border: none;
}

.icon-button-2 {
	position: static;
	padding: none;
	margin-left: 10px;
	background-color: transparent;
	border: none;
}

.socialmedia-button {
	width: 25px;
}

.copyright {
	font-size: 11px;
}

@media only screen and (min-width: 1054px) {
	.footer-grid {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		-webkit-align-items: start;
		        align-items: start;
		justify-items: start;
		padding-top: 15px;
		padding-left: 10px;
	}
}

@media only screen and (max-width: 1054px) and (min-width: 768px) {
	.footer-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		-webkit-align-items: start;
		        align-items: start;
		justify-items: start;
		padding-top: 15px;
		padding-left: 10px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 480px) {
	.footer-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		-webkit-align-items: start;
		        align-items: start;
		justify-items: start;
		padding-top: 15px;
		padding-left: 10px;
	}
}

@media only screen and (max-width: 480px) {
	.footer-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		-webkit-align-items: start;
		        align-items: start;
		justify-items: start;
		padding-top: 5px;
		padding-left: 5px;
	}

	.footer-div {
		margin-bottom: 20px;
	}
}

.footer-line {
	background-color: #ffce8a;
}


.wrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  padding-top:10px;
  padding-bottom: 20px;
  outline: none;
}
.wrapper .input-data{
  height: 35px;
  width: 100%;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  border-radius: 0;
  outline: none;
}
.wrapper .input-data input{
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
  border-radius: 0;
  outline: none;
}
.input-data input:focus ~ label,
.input-data input:valid ~ label{
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  font-size: 12px;
  color: #797979;
  border-radius: 0;
  outline: none;
}
.wrapper .input-data label{
  position: absolute;
  bottom: 0px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
  border-radius: 0;
  outline: none;
}
.input-data .underline{
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  border-radius: 0;
  outline: none;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #4158d0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  border-radius: 0;
  outline: none;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before{
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    -webkit-appearance: none;
}


.main-profile-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100vw;
    position: relative;
}

.cover-photo{
    width: 100%;
    background-color: #F8F8F8;
    height: 275px;
    position:absolute;
}

.profile-details{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
}

.profile-picture-div{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    position: relative;
    top: 100px;
    padding-bottom: 100px;
}

.profile-image{
    height:25vh;
    width:25vh;
    border-radius: 50%;
}


.profile-info-div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: center;
            align-content: center;
    position: relative;
    justify-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid rgb(230, 230, 230);
}

.display-name{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    font-size: 4vh;
    font-family: 'Montserrat';
    font-weight: 500;
    color: rgb(30, 30, 30);
}

.email{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    font-size: 1.75vh;
    font-family: 'Montserrat';
    font-weight: 300;
    color: rgb(150, 150, 150);
    padding: 0px 5px 0px 5px;

}

.class-teacher{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    font-size: 2vh;
    font-family: 'Montserrat';
    font-weight: 300;
    margin-right: 7.5%;
}

.bold-stats{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: 500;
    width: 100%;
}

.profile-button-div {
    position: absolute;
    top: 0vh;
    right: 3vw;
}

.profile-button {
    display: -webkit-flex;
    display: flex;
    font-size: 1.75vh;
    border-width: 0px;
    outline-width: 0px;
    background-color: whitesmoke;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    width:100%;
}

.profile-button:hover{
    border-color: rgb(206, 206, 206);
    background: rgb(110, 110, 110);;
}

.other-profile-info-div{      
    display: -webkit-flex;      
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; 
    -webkit-flex-wrap: wrap; 
            flex-wrap: wrap;
    width: 200px;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.biography{
    width: 95%;
    padding: 1% 5% 2.5% 5%;
    overflow:hidden;
    max-height: 100px;
    color:rgb(100, 100, 100);
    text-align: justify;
    text-justify: inter-word;

}

.profile-stats{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: center;
            align-content: center;
    position: relative;
    justify-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0%;
    width: 100%;
    border-bottom: 1px solid rgb(230, 230, 230);
}

.stat-container{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 125px;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    border-left: 1px solid rgb(230, 230, 230);
    border-right: 1px solid rgb(230, 230, 230);
    padding: 5%;
    overflow: hidden;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.stat-card{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 125px;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-animation: stats-bounce-in 1s cubic-bezier(0.71, 0.03, 0, 0.99) 0.5s forwards;
            animation: stats-bounce-in 1s cubic-bezier(0.71, 0.03, 0, 0.99) 0.5s forwards;
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@-webkit-keyframes stats-bounce-in {
    from{-webkit-transform: translateY(150px);transform: translateY(150px);}
    to{-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes stats-bounce-in {
    from{-webkit-transform: translateY(150px);transform: translateY(150px);}
    to{-webkit-transform: translateY(0px);transform: translateY(0px);}
}

.stat-container:first-child{
    border-left: 0px;
    border-right: 1px solid rgb(230, 230, 230);
}
.stat-container:last-child{
    border-right: 0px;
    border-left: 1px solid rgb(230, 230, 230);
}

.stat-label{
    font-weight: 600;
    font-size: 7vh;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.stat-value{
    font-weight: 600;
    font-size: 2vh;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.stat-container:hover{
    background-color: var(--secondary-color);
}
.stat-container:hover .stat-explore{
    visibility:visible;
    opacity: 1;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.stat-explore{
    width: 10px;
    font-weight: 600;
    font-size: 3vh;
    visibility:hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.05s, -webkit-transform 0.1s ease-in;
    transition: visibility 0s, opacity 0.05s, transform 0.1s ease-in;
    transition: visibility 0s, opacity 0.05s, transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}


.bookshelf-container{
    margin-top: 7.5px;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    padding: 2%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 7.5px;
    overflow: hidden;
}

.bookshelf-container:first-child{
    margin-top: 15px;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    padding: 2%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 7.5px;
    overflow: hidden;
}

.bookshelf-container:last-child{
    margin-top: 7,5px;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    padding: 2%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
}

.bookshelf-content{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-animation: books-bounce-in 1s cubic-bezier(0.71, 0.03, 0, 0.99) 0.8s forwards;
            animation: books-bounce-in 1s cubic-bezier(0.71, 0.03, 0, 0.99) 0.8s forwards;
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    min-height: 200px;


}

@-webkit-keyframes books-bounce-in {
    from{-webkit-transform: translateX(-1000px);transform: translateX(-1000px);}
    to{-webkit-transform: translateX(0px);transform: translateX(0px);}
}

@keyframes books-bounce-in {
    from{-webkit-transform: translateX(-1000px);transform: translateX(-1000px);}
    to{-webkit-transform: translateX(0px);transform: translateX(0px);}
}

.bookshelf-title{
    font-weight: 700;
    font-size: 2vh;
    padding-bottom: 2%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


.profile-book-container a{
    width: 125px;
    margin: 5px 30px 5px 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: -webkit-transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out
}


.profile-book-container a:hover{
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    font-weight: 500;
}

.profile-book-cover{
    width: 100px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.profile-book-cover img{
    width: 100px;
    height: 150px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.profile-book-title{
    text-align: center;
    vertical-align: center;
    width: 125px;
}


:root {
	--main-color: #243245;
	--secondary-color: whitesmoke;
	--third-color: rgb(235, 210, 164);
	--bg: #242526;
	--bg-accent: #484a4d;
	--text-color: #dadce1;
	--reverse-text-color: black;
	--nav-size: 75px;
	--border: 1px solid #474a4d;
	--border-radius: 8px;
	--speed: 500ms;
}

body {
	min-width: 400px;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	color: black;
	color: var(--reverse-text-color);
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	color: black;
	color: var(--reverse-text-color);
}

* {
	font-family: 'Montserrat';
}

@media only screen and (max-width: 1050px) {
	.navbar {
		height: 75px;
		height: var(--nav-size);
		background-color: #243245;
		background-color: var(--main-color);
		display: -webkit-flex;
		display: flex;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 10;
		-webkit-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
		min-width: 400px;
	}

	.navbar-nav-left {
		height: 100%;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-content: flex-start;
		        align-content: flex-start;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		justify-items: flex-start;
	}

	.navbar-nav-left-burger {
		display: -webkit-flex;
		display: flex;
		position: fixed;
		right: 50px;
		top: 20px;
	}

	.hamburger-icon {
		background-color: transparent;
		outline: none;
		border: none;
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg);
		font-size: 25px;
		color:#ffce8a;

	}
	.hamburger-icon:hover {
		font-weight: 500;
	}

	.hamburger-dropdown {
		background-color: transparent;
		outline: none;
		border: none;
		padding-left: 15px;
		display: -webkit-flex;
		display: flex;
	}

	.hamburger-dropdown-ul {
		position: absolute;
		right: 10px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
		border-radius: 5px;
		background-color: #243245;
		width: 200px;
		-webkit-transform: translateY(40px);
		        transform: translateY(40px);
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}

	.nav-item {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		font-family: 'Montserrat';
		font-weight: 500;
		width: 100%;
		justify-items: center;
		-webkit-align-content: center;
		        align-content: center;
		-webkit-justify-content: center;
		        justify-content: center;
		padding: 10px 0px 10px 0px;
		color: #ffce8a;
	}

	.nav-item:hover {
		background-color: #ffce8a;
		color: #243245
	}

	.nav-item:first-child:hover {
		background-color: #ffce8a;;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		color: #243245
	}

	.nav-item:last-child:hover {
		background-color: #ffce8a;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		color: #243245
	}

	.navbar-nav-right {
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		position: relative;
	}

	.navbar-nav-left {
		position:relative;
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
	}

	.navbar-nav-left-items {
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 50px;
		display: none;
	}

	.nav-item-link-button {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: rgba(0, 0, 0, 0.74);
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text {
		display: -webkit-flex;
		display: flex;
		width: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		color: white;
	}

	.nav-item-link-button-inverted {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: white;
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text-inverted {
		display: -webkit-flex;
		display: flex;
		width: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		color: black;
	}

	.nav-item-link-button-text-inverted:hover {
		color: white;
	}

	.nav-item-dropdown {
		width: calc(75px * 0.8);
		width: calc(var(--nav-size) * 0.8);
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-left: 10px;
	}

	.icon-button {
		--button-size: calc(var(--nav-size) * 0.5);
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		padding: 5px;
		margin: 2px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		transition: -webkit-filter 300ms;
		transition: filter 300ms;
		transition: filter 300ms, -webkit-filter 300ms;
		color: black;
	}

	.icon-button:hover {
		-webkit-filter: brightness(1.2);
		        filter: brightness(1.2);
	}

	.dp-icon {
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		--button-size: calc(var(--nav-size) * 0.5);
		border-radius: 50%;
	}

	.dp-button:hover {
		-webkit-filter: brightness(1.2);
		        filter: brightness(1.2);
	}

	.drop-button {
		width: 15px;
		height: 15px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.logo {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		padding-left: 2.5%;
	}

	.logo-icon {
		height: 50px;
	}

	.logo-text {
		font-family: 'Comfortaa';
		font-size: large;
		color: black;
		text-decoration: none;
	}

	.logo-text:hover {
		text-decoration: none;
		color: black;
	}

	.language-div {
		margin-left: 10px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.language-text {
		font-size: 11px;
		color: #ffce8a;
	}

	.drop-down-menu {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}

	.drop-down-menu ul {
		position: absolute;
		top: calc(75px * 0.6);
		top: calc(var(--nav-size) * 0.6);
		right: calc(75px * 0.925);
		right: calc(var(--nav-size) * 0.925);
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
		border-radius: 5px;
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		width: 17.5vh;
		-webkit-transform: translateY(7.5px);
		        transform: translateY(7.5px);
	}

	.drop-down-menu a {
		-webkit-align-content: center;
		        align-content: center;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		padding: 15px 20px 15px 20px;
	}

	.drop-down-menu button {
		-webkit-align-content: center;
		        align-content: center;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		padding: 15px 20px 15px 20px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.drop-down-menu a:first-child:hover {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.drop-down-menu a:hover {
		background-color: rgb(235, 210, 164);
		background-color: var(--third-color);
	}

	.drop-down-menu button:first-child:hover {
		background-color: transparent;
	}

	.drop-down-menu button:hover {
		background-color: rgb(235, 210, 164);
		background-color: var(--third-color);
	}

	.drop-down-menu button {
		border: none;
		background: transparent;
	}

	.sidebar-container {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		width: 100%;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		min-width: 900px;
		font-size: 14px;
	}

	.sidebar-item {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}

	.sidebar-item img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item a {
		padding: 10px;
		width: 100%;
		height: 100%;
		transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		transition: transform 0.2s, -webkit-transform 0.2s;
	}

	.sidebar-item a:hover {
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		border-bottom: 2px solid rgb(235, 210, 164);
		border-bottom: 2px solid var(--third-color);
		-webkit-transform: translateY(2px);
		        transform: translateY(2px);
	}

	.sidebar-item-active {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		width: 16vw;
		-webkit-align-items: center;
		        align-items: center;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		-webkit-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}

	.sidebar-item-active img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item-active a {
		padding: 10px;
		width: 100%;
		height: 100%;
		transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	}

	.sidebar-main-content {
		padding: 10px;
		width: 100%;
		position: relative;
		word-wrap: break-word;
	}

	.topbar-container {
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		position: relative;
	}

	.topbar-item {
		display: -webkit-flex;
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;
		transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	}

	.topbar-item:hover {
		-webkit-transform: translateY(-1px);
		        transform: translateY(-1px);
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		border-bottom: 3px solid rgb(235, 210, 164);
		border-bottom: 3px solid var(--third-color);
		cursor: pointer;
	}

	.topbar-item-active {
		display: -webkit-flex;
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;
		border-bottom: 3px solid #243245;
		border-bottom: 3px solid var(--main-color);
		cursor: pointer;
		font-weight: 700;
		background-color: whitesmoke;
		background-color: var(--secondary-color);
	}
}

@media only screen and (min-width: 1050px) {
	.navbar {
		height: 75px;
		height: var(--nav-size);
		background-color: #243245;
		background-color: var(--main-color);
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 10;
		-webkit-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}

	.navbar-nav-right {
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		position: relative;
		justify-items: center;
	}

	.navbar-nav-left {
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
	}

	.navbar-nav-left-items {
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 50px;
	}

	.navbar-nav-left-items a {
		height: 100%;
		justify-items: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
	}

	.nav-item {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 400;
		width: 90px;
		justify-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		height: 100%;
		transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
		color: #ffce8a;
	}

	.nav-item:hover {
		border-bottom: 2px solid whitesmoke;
		border-bottom: 2px solid var(--secondary-color);
		-webkit-transform: translateY(-1px);
		        transform: translateY(-1px);
	}

	.nav-item-link-button {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: rgba(0, 0, 0, 0.74);
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text {
		display: -webkit-flex;
		display: flex;
		width: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		color: white;
	}

	.nav-item-link-button-inverted {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		margin-left: 7px;
		margin-right: 7px;
		font-family: 'Montserrat';
		font-weight: 200;
		font-size: small;
		border-radius: 30px;
		background-color: white;
		width: 100px;
		height: 40px;
		justify-items: center;
	}

	.nav-item-link-button-text-inverted {
		display: -webkit-flex;
		display: flex;
		width: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		color: black;
	}

	.nav-item-link-button-text-inverted:hover {
		color: white;
	}

	.nav-item-dropdown {
		width: calc(75px * 0.8);
		width: calc(var(--nav-size) * 0.8);
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-left: 10px;
	}

	.icon-button {
		--button-size: calc(var(--nav-size) * 0.5);
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		padding: 5px;
		margin: 2px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		transition: -webkit-filter 300ms;
		transition: filter 300ms;
		transition: filter 300ms, -webkit-filter 300ms;
		color: black;
	}

	.icon-button:hover {
		-webkit-filter: brightness(1.2);
		        filter: brightness(1.2);
	}

	.dp-icon {
		width: var(--button-size);
		height: var(--button-size);
		border-radius: 50%;
		--button-size: calc(var(--nav-size) * 0.5);
		border-radius: 50%;
	}

	.dp-button:hover {
		-webkit-filter: brightness(1.2);
		        filter: brightness(1.2);
	}

	.drop-button {
		width: 15px;
		height: 15px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.logo {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		padding-left: 2.5%;
	}

	.logo-icon {
		height: 50px;
	}

	.logo-text {
		font-family: 'Comfortaa';
		font-size: large;
		color: black;
		text-decoration: none;
	}

	.logo-text:hover {
		text-decoration: none;
		color: black;
	}

	.language-div {
		margin-left: 10px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.language-text {
		font-size: small;
		color: rgba(0, 0, 0, 0.5);
	}

	.drop-down-menu {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}

	.drop-down-menu ul {
		position: absolute;
		top: calc(75px * 0.6);
		top: calc(var(--nav-size) * 0.6);
		right: calc(75px * 0.925);
		right: calc(var(--nav-size) * 0.925);
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
		border-radius: 5px;
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		width: 17.5vh;
		-webkit-transform: translateY(7.5px);
		        transform: translateY(7.5px);
	}

	.drop-down-menu a {
		-webkit-align-content: center;
		        align-content: center;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		padding: 15px 20px 15px 20px;
	}

	.drop-down-menu button {
		-webkit-align-content: center;
		        align-content: center;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		padding: 15px 20px 15px 20px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.drop-down-menu a:first-child:hover {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.drop-down-menu a:hover {
		background-color: rgb(235, 210, 164);
		background-color: var(--third-color);
	}

	.drop-down-menu button:first-child:hover {
		background-color: transparent;
	}

	.drop-down-menu button:hover {
		background-color: rgb(235, 210, 164);
		background-color: var(--third-color);
	}

	.drop-down-menu button {
		border: none;
		background: transparent;
	}

	.navbar-nav-left-burger {
		display: none;
	}

	.sidebar-container {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		width: 18vw;
		position: fixed;
	}

	.sidebar-item {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		width: 16vw;
		-webkit-align-items: center;
		        align-items: center;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		-webkit-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}

	.sidebar-item img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item a {
		padding: 15px;
		width: 100%;
		height: 100%;
		transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
		font-size: 16px;
	}

	.sidebar-item a:hover {
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		border-bottom: 2px solid rgb(235, 210, 164);
		border-bottom: 2px solid var(--third-color);
		-webkit-transform: translateX(3px);
		        transform: translateX(3px);
	}

	.sidebar-item-active {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		width: 16vw;
		-webkit-align-items: center;
		        align-items: center;
		border-bottom: 0.25px solid rgb(233, 233, 233);
		-webkit-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
		font-size: 16px;
	}

	.sidebar-item-active img {
		width: 30px;
		height: 30px;
	}

	.sidebar-item-active a {
		padding: 15px;
		width: 100%;
		height: 100%;
		transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	}

	.sidebar-main-content {
		padding: 10px;
		left: 18vw;
		width: 80vw;
		position: relative;
		word-wrap: break-word;
		min-height: 500px;
	}

	.topbar-container {
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		left: 18vw;
		width: 80vw;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		position: relative;
	}

	.topbar-item {
		display: -webkit-flex;
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;
		transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	}

	.topbar-item:hover {
		-webkit-transform: translateY(-1px);
		        transform: translateY(-1px);
		background-color: whitesmoke;
		background-color: var(--secondary-color);
		border-bottom: 3px solid rgb(235, 210, 164);
		border-bottom: 3px solid var(--third-color);
		cursor: pointer;
	}

	.topbar-item-active {
		display: -webkit-flex;
		display: flex;
		padding: 5px;
		width: 100%;
		height: 100%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;
		border-bottom: 3px solid #243245;
		border-bottom: 3px solid var(--main-color);
		cursor: pointer;
		font-weight: 700;
		background-color: whitesmoke;
		background-color: var(--secondary-color);
	}

	.class-list-table-container {
		width: 100%;
		border: 1px solid black;
		overflow: hidden;
	}

	.class-list-table-container td {
		overflow-wrap: break-word;
	}

	.column-header {
		border: 1px solid black;
		width: 100px;
	}
}

.book-page-container{
    width: 90vw;
    margin: 5%;
}

.book-overview-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;

}

.back-to-search-div{
    height: 75px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.book-summary-photo-container{
    margin: 0 5% 0 5%;
}

.book-summary-container{
    width: 80%;
    margin: 0 10% 0 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center ;
            justify-content: center ;
}

.book-summary-container img{
    height: 300px;
    width: 200px;
}

.book-summary-info{
    padding: 0 20px 0 20px;
}

.book-summary-book-title{
    font-size: 24pt;
    font-weight: 600;
    padding-right: 10px;
}

.book-summary-book-year-published{
    font-style: italic;
}

.book-summary-ratings{
    padding: 5px 0 5px 0 ;
    font-size: 14pt;
}

.book-summary-ratings label{
    font-size: 10pt;
    font-style: italic;
    padding-left: 10px;
}

.book-summary-genre{
    padding: 20px 0 20px 0;
    
}

.book-summary-genre label{
    background-color: var(--secondary-color);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-size: 8pt;
    font-weight: 600;
}

.book-summary-action-bar{
    width: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 10px 0 10px 0;
    -webkit-align-items: center;
            align-items: center;
}

.book-summary-action-bar button{
    padding: 5px 10px 5px 10px;
    width: 175px;
    border-radius: 5px;
    font-size: 8pt;
    font-weight: 500;
    border: none;
}

.add-to-favourites{
    background-color: var(--main-color);
}

.remove-from-favourites{
    background-color:rgb(190, 92, 92)
}
.class-list-table-container{
    overflow:hidden;
    border: none;
    table-layout:fixed;
    width:100%;
}



.class-list-table-headers{
    background-color: var(--secondary-color);
    border-bottom: 1.5px solid darkgrey;
}

.column-header{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.column-header-sortable{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    overflow:hidden;
    white-space:nowrap;
}

.column-header-action{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.column-header-sortable:hover{
    background-color: rgb(231, 230, 230);
}

.data-field{
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: center;
    font-size: 1.5vh;
    overflow:hidden;
}

.data-field-action{
    padding: 1rem;
    text-align: center;
    font-size: 2vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.data-row{
    border-bottom: 0.5px solid rgb(218, 218, 218);
    background-color: var(--secondary-color);
}

.data-row:hover{
    background-color: white;
}

.user-roles-container{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.user-role-button{
    display:-webkit-flex;
    display:flex;
    background-color: rgb(202, 202, 202);
    padding: 2px 3px 2px 3px;
    margin: 1px 2px 1px 2px;
    border-radius: 5px;
    font-size: 1vh;
    font-weight: 600;
    min-width: 2.5rem;
    -webkit-align-content:center;
            align-content:center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.user-role-button:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.user-role-button-special{
    display:-webkit-flex;
    display:flex;
    background-color: var(--third-color);
    padding: 2px 3px 2px 3px;
    margin: 1px 2px 1px 2px;
    border-radius: 5px;
    font-size: 1vh;
    font-weight: 600;
    min-width: 2.5rem;
    -webkit-align-content:center;
            align-content:center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.user-role-button-special:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.classroom-list-action-button{
    cursor: pointer;
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    color: rgb(158, 158, 158);
    font-weight: 500;
    width: 15%;
}

.classroom-list-action-menu{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: var(--main-color);
    position: absolute;
    right: 3vh;
    margin-top: 5vh;
    border-radius: 5px;
}


.classroom-list-action-menu-item{
    padding: 0.5rem;
    font-size: 0.75rem;
}

.classroom-list-action-menu-item:hover{
    background-color: var(--third-color);
    cursor: pointer;
}
.classroom-list-action-menu-item:first-child:hover{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.classroom-list-action-menu-item:last-child:hover{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.no-classes-assigned{
    width: 100%;
    background-color:rgb(252, 252, 252);
    text-align: center;
    padding: 15% 15% 25% 15%;
    border-radius: 5px;
}

.no-classes-assigned label{
    font-size: 24pt;
    font-style: italic;
    color: rgb(73, 73, 73);
}
.no-classes-assigned p{
    font-size: 18pt;
    font-style: italic;
    color: grey;
}
.no-classes-assigned h2{
    font-size: 48pt;
}
.class-library-title-select{
    width: 100%;
}

.class-library-main-content-container{
    width: 100%;
    height: 100%;
    padding: 20px 10px 20px 10px;
    margin: 20px 0 20px 0;
    background-color: var(--secondary-color);
    position:relative;
}

.class-library-book-container{
    position: relative;
    border-radius: 5px;
    margin: 0px;
}

.class-library-book-container-editing{
    position: relative;
    border-radius: 5px;
    margin: 0px;
    background-color: var(--secondary-color);
}

.class-library-book-container-editing:hover{
    position: relative;
    border-radius: 5px;
    border: 1px dotted rgb(168, 168, 168) ;
    z-index: 3;
}

.class-library-book-a-tag{
    width: 125px;
    margin: 5px 30px 5px 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: -webkit-transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out
}

.class-library-delete-book{
    position: absolute;
    border: none;
    background-color: black;
    color: white;
    border-radius: 50%;
    left: 15px;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 9pt;
}

.class-library-book-container button:hover{
    color: black;
    background-color: white;
}


.class-library-book-container a:hover{
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    font-weight: 500;
}

.class-library-book-cover{
    width: 150px;
    height: 225px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
}

.class-library-book-cover img{
    width: 150px;
    height: 225px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.class-library-book-title{
    text-align: left;
    vertical-align: center;
    font-size: 11pt;
    width: 150px;
    font-weight: 600;
}

.class-library-book-author{
    text-align: left;
    vertical-align: center;
    font-size: 9pt;
    width: 150px;
    font-weight: 300;
}

.class-library-action-bar{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
}


.class-library-sortby{
    padding-left: 15px;
}

.class-library-bookshelf-container{
    margin: 20px 0 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.class-library-title{
    font-size: 16pt;
    font-weight: 600;
    margin: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.class-library-title-input{
    font-size: 16pt;
    font-weight: 600;
    margin: 15px;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid rgb(233, 233, 233);
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    transition: -webkit-transform 0.5s cubic-bezier(0.1, 1.01, 1, 1);
    transition: transform 0.5s cubic-bezier(0.1, 1.01, 1, 1);
    transition: transform 0.5s cubic-bezier(0.1, 1.01, 1, 1), -webkit-transform 0.5s cubic-bezier(0.1, 1.01, 1, 1);
    width: 50%;
}

.class-library-title-input:focus{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    border-bottom: 2px solid rgb(168, 168, 168);
}



.class-library-add-book-input input{
    font-weight: 600;
    margin: 15px 0 15px 15px;
    padding: 5px;
    background-color: transparent;
    border: 2px solid rgb(168, 168, 168);
    width: 25%;
}

.class-library-add-book-input button{
    border: 2px solid rgb(168, 168, 168);
    background-color: rgb(168, 168, 168);
    width: 35px;
    margin: 15px 0 15px 0;
    padding: 5px;
}

.class-library-add-book-input button:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.class-library-edit{
    border: none;
    margin-left: 15px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    background-color: rgb(168, 168, 168);
    font-size: 10pt;
}

.class-library-edit:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.class-library-action-save-cancel{
    margin: 30px 15px 10px 20px; 
    border-top: 1px solid rgb(214, 213, 213);
}
.class-library-action-save-cancel button{
    margin: 10px 10px 0 0;
    border: none;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
}

.class-library-action-save-cancel button:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2)
}

.class-library-save{
    background-color: var(--main-color);
}

.class-library-delete{
    background-color: rgb(255, 85, 85);
}
.class-library-edit{
    background-color: rgb(168, 168, 168);
}

.class-library-dragging{
    opacity: 0.2;
}
.box {
	width: 100%;
	min-width: 300px;
}

.box2 {
	margin-left: auto;
	margin-right: auto;
}

.book-item-search-new-release-container{
	display:-webkit-flex;
	display:flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
}
.book-item-search-new-release{
	width: 175px;
	height:250px;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-self: center;
	        align-self: center;
	-webkit-align-items: center;
	        align-items: center;
	text-align: center;
}

.search {
	position: relative;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: center;
	        justify-content: center;
	justify-items: center;
	margin: 100px 0 100px 0;
}

.search-bar-inner {
	position: relative;
	width: 75%;
}

.search-icon {
	position: absolute;
	color: gray;
	left: 10px;
	top: 15px;
	height: 20px;
	width: 20px;
	transition: -webkit-transform 0.1s ease-in-out;
	transition: transform 0.1s ease-in-out;
	transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.search-bar {
	display: block;
	width: 100%;
	height: 50px;
	font-size: 12px;
	padding-left: 40px;
	font-size: 16pt;
	border: none;
	border-bottom: 3px solid rgb(204, 204, 204);
	color: gray;
	font-weight: 500;
}

.search-bar:focus {
	border-bottom: 3px solid rgb(63, 63, 63);
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.search-bar:focus ~ .search-icon {
	color: rgb(63, 63, 63);
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}

.search-bar:focus ~ .search-bar-enter {
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.search-bar-enter {
	position: absolute;
	right: 15px;
	top: 20px;
	height: 20px;
	width: 75px;
	min-width: 75px;
	color: rgb(204, 204, 204);
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.header {
	display: block;
	font-size: 35px;
	font-weight: 700;
	width: 100%;
	color: #452724;
}
.header p {
	width: 100%;
	text-align: center;
}

.header-for-new-release {
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	-webkit-align-self: center;
	        align-self: center;
	color: #452724;
	padding: 50px 0 15px 0 ;
}

.sort-description {
	margin-bottom: 0px;
	font-size: 12px;
}

.sort-dropdown {
	margin-top: 0px;
	border-radius: 6px;
	font-size: 12px;
}

.new-release-box {
	width: 100%;
	background-color: #fff7ec;
	margin: 75px 0 75px 0;
}

.book-cover {
	width: 120px;
	height: 190px;
	border-radius: 3px;
	margin-bottom: 0px;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	margin-left: auto;
	margin-right: auto;
	position: inherit;
}

.results-book-cover {
	width: 130px;
	height: 206px;
	border-radius: 3px;
	margin-bottom: 0px;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	margin-left: auto;
	margin-right: auto;
	position: inherit;
	padding: 5px;
}

.col-3 {
	text-align: center;
}

.book-title {
	padding: 0;
	margin-top: 5px;
	font-size: 14px;
}

.filter-box {
	/* background-color: #e5e5e5; */
	width: 700px;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	padding: 22px;
	border-radius: 15px;
}

.sort-bar {
	margin-top: 13px;
}

.rowC {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	width: 100%;
}

.results-image {
	width: 130px;
	height: 220px;
	/* background-color: red; */
	text-align: center;
	position: relative;
	margin-left: 25px;
}

.results-description {
	height: 220px;
	/* background-color: #89c8e6; */
	margin-left: 15px;
	width: 100%;
}

.results-box {
	width: 700px;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	width: 75%;
}

.results-title {
	/* background-color: rosybrown; */
	font-size: 20px;
	font-weight: 700;
	bottom: 0;
	width: 100%;
}

.results-year {
	/* background-color: seashell; */
	font-size: 11px;
	bottom: 0;
	width: 100px;
	font-style: italic;
	padding-top: 9px;
	margin-left: 6px;
}

.box3 {
	height: 30px;
	width: 100%;
	overflow-x: auto;
	/* background-color: wheat; */
}

.author-name {
	/* background-color: rgb(240, 236, 35); */
	font-size: 12px;
	margin-bottom: -17px;
}

.rating {
	/* background-color: lightgreen; */
	margin-top: 0px;
}

.rating-number {
	/* background-color: lightyellow; */
	font-size: 11px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-style: italic;
	margin-left: 5px;
	padding-top: 5px;
}

.save-button {
	font-size: 10px;
	border-radius: 9px;
	border: 1px solid #525252;
	padding: 3px 9px 3px 9px;
	margin-top: 10px;
	background: white;
	transition: 0.2s;
	color: #525252;
}

.save-button:hover {
	background-color: #525252;
	color: white;
}

.favourites-button {
	border-radius: 100px;
	border: 1px solid black;
	background: white 50%;
	padding: 0px 5px 0px 5px;
	opacity: 0.7;
	top: 77%;
	left: 71%;
	position: absolute;
}

.book-description {
	font-size: 10pt;
	max-height: 60%;
	overflow-y: auto;
}




.search-info-box{
	margin-left: 15%;
}
.search-filter-bar-container {
	width: 100%;
	padding: 15px 15% 15px 15%;
	background-color: #243245;
	margin: 10px 0 35px 0;
}

.genre-not-selected {
	border: none;
	border-radius: 3px;
	padding: 5px 10px 5px 10px;
	margin: 10px;
	background-color: #fff7ec;
	color: #243245;
	font-weight: 600;
	font-size: 12px;
}

.search-filter-bar-container button:hover {
	background-color: #ffce8a;
	color: #243245;
}

.genre-selected {
	border: none;
	border-radius: 3px;
	padding: 5px 10px 5px 10px;
	margin: 10px;
	background-color: #ffce8a;
	color: #243245;
	font-weight: 600;
	font-size: 12px;
}

.search-page-buttons-bar {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: center;
	        justify-content: center;
	justify-items: center;
	margin: 20px 0 20px 0;
}

.search-page-buttons {
	border: none;
	padding: 5px 10px 5px 10px;
	margin: 3px;
	background-color: #fff7ec;
}
.search-page-buttons-active {
	border: none;
	padding: 5px 10px 5px 10px;
	margin: 3px;
	background-color: #ffce8a;
	font-weight: 500;
}

.search-page-buttons:hover {
	background-color: #ffce8a;
}

.my-class-modal{
    position: fixed;
    top: -10%;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    background-color: #FFF;
    width: 75%;
    padding: 50px;
    z-index: 999;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column
}

.my-class-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0 ;
    background-color: rgba(0,0,0,0.7);
}

.my-class-modal-close-button{
    position: fixed;
    top: 0;
    right: 0;
    border-top-right-radius: 5px;
    padding: 10px 15px 10px 15px;
    border:none;
    font-weight: 700;
}

.existing-roles-container{
    margin-bottom: 50px;
}

.existing-roles-content-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: var(--secondary-color);
    border-radius: 5px;
    min-height: 50px;
    margin: 2.5px 0 2.5px 0;
}

.existing-role-object{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    background-color: var(--third-color);
    padding: 5px 5px 5px 15px;
    border-radius: 5px;
    margin: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.existing-role-object:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2)
}


.existing-role-object button{
    border: none;
    background-color: transparent;
    font-weight: 700;
    font-size: 0.75rem;
}

.existing-role-label{
    font-size: 0.75rem;
    font-weight: 600;
}

.add-roles-container{
    width: 100%;
}

.add-roles-input-field{
    width: 25%;
    position: relative;
    font-size: 0.9rem;
}

.add-roles-input-field input{
    width: 100%;
    padding: 7.5px 20px 7.5px 20px;
    border: none;
    background-color: var(--secondary-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
}


.add-roles-add-button{
    border: none;
    background-color: rgb(221, 221, 221);
    padding: 7.5px 15px 7.5px 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 700;
    position: fixed;
    left: 25%;
}

.add-roles-save-button{
    margin-top: 15px;
    background-color: var(--main-color);
    padding: 7.5px 15px 7.5px 15px;
    border:none;
    border-radius:5px;
    font-weight: 600;
}

.add-roles-add-button:hover, .add-roles-save-button:hover, .my-class-modal-close-button:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2)
}


.default-roles-to-add-container{
    margin: 5px 0 10px 0;
    background-color: var(--secondary-color);
}

.default-roles-to-add-container button{
    border: none;
    background-color: var(--third-color);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin: 7.5px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 10pt;
    font-weight: 600;
}
.class-files-table-container{
    overflow:hidden;
    border: none;
    table-layout:fixed;
    width:100%;
}


.class-files-table-headers{
    background-color: var(--secondary-color);
    border-bottom: 1.5px solid darkgrey;
}

.class-files-column-header{
    padding: 1rem 1rem 0 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.class-files-column-header-sortable{
    padding: 1rem 1rem 0 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    overflow:hidden;
    white-space:nowrap;
}

.class-files-column-header-action{
    padding: 1rem;
    text-align: center;
    font-size: 1.5vh;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow:hidden;
    white-space:nowrap;
}

.class-files-column-header-sortable:hover{
    background-color: rgb(231, 230, 230);
}

.class-files-data-field{
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: center;
    font-size: 1.5vh;
    overflow:hidden;
}


.class-files-data-row{
    border-bottom: 0.5px solid rgb(218, 218, 218);
    background-color: var(--secondary-color);
    cursor: pointer;
}

.class-files-data-row:hover{
    background-color: white;
}

.class-files-action-bar{
    width: 100%;
    background-color: var(--secondary-color);
    border-bottom: 1px solid rgb(219, 219, 219);
}

.class-files-action-bar button{
    padding: 2.5px 20px 2.5px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    font-size: 12pt;
    color: white;
    background: rgb(116, 116, 116);
}

.class-files-action-bar button:hover{
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2)
}

.class-files-new-folder-input{
    margin: 5% 5% 5% 5%;
    border-radius: 5px;
    border: 1px solid rgb(116, 116, 116);
    padding: 10px; 
}

.class-files-folder-tree{
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid rgb(219, 219, 219);
    margin-bottom: 15px;
}

.class-files-tree-object{
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.class-files-tree-object:hover{
    background-color: var(--secondary-color);

}
.star {
	margin-top: 0px;
}

input[type='radio'] {
	display: none;
}

.leaderboard-box {
	width: 100%;
}
@media only screen and (max-width: 1050px) {
	.leaderboard-box2 {
		width: 95%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}
@media only screen and (min-width: 1050px) {
	.leaderboard-box2 {
		width: 84%;
		right: 0;
		margin-left: 16%;
	}
}

.filter-section {
	/* background-color: salmon; */
	position: relative;
	min-height: 80px;
}

.filter {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	right: 0;
	margin: 30px 30px 0px 0px;
}

.filter-description {
	margin-bottom: 0px;
	font-size: 12px;
}

.sort-dropdown {
	margin-top: 0px;
	border-radius: 6px;
	font-size: 12px;
}

.col-4 {
	text-align: center;
}

.top-three-section {
	margin-bottom: 20px;
}

/* Second Place CSS */

.second-place-profile-picture {
	width: 90px;
	/* border: black 1px solid; */
	border-radius: 100px;
}

.second-place-name {
	font-weight: 600;
	margin-top: 7px;
	margin-bottom: -3px;
}

.second-place-points {
	font-size: 14px;
	font-weight: 600;
	color: #585858;
}

.second {
	margin: 0px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	font-weight: 700;
	font-size: 24px;
}
.second-position {
	position: relative;
	/* background-color: skyblue; */
	width: 180px;
	margin-left: auto;
	margin-right: auto;
}

/* First Place CSS */

.first-place-profile-picture {
	width: 110px;
	/* border: black 1px solid; */
	border-radius: 100px;
}

.first-place-name {
	font-weight: 600;
	margin-top: 7px;
	margin-bottom: -3px;
	font-size: 18px;
}

.first-place-points {
	font-size: 16px;
	font-weight: 600;
	color: #585858;
}

.first {
	margin: 0px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	font-weight: 700;
	font-size: 30px;
}
.first-position {
	position: relative;
	/* background-color: skyblue; */
	width: 190px;
	margin-left: auto;
	margin-right: auto;
}

/* Third Place CSS */

.third-place-profile-picture {
	width: 80px;
	/* border: black 1px solid; */
	border-radius: 100px;
}

.third-place-name {
	font-weight: 600;
	margin-top: 7px;
	margin-bottom: -3px;
	font-size: 15px;
}

.third-place-points {
	font-size: 13px;
	font-weight: 600;
	color: #585858;
}

.third {
	margin: 0px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	font-weight: 700;
	font-size: 20px;
}
.third-position {
	position: relative;
	width: 150px;
	margin-left: auto;
	margin-right: auto;
}

.profile-picture {
	width: 43px;
	height: 43px;
	/* border: black 1px solid; */
	border-radius: 100px;
	margin-left: 15px;
	margin-right: 20px;
}

.even-fourth {
	height: 70px;
	background-color: #f4f4f4;
	position: relative;
}

.odd-fourth {
	height: 70px;
	background-color: white;
	position: relative;
}

.leaderboard-rank {
	margin-right: 10px;
	font-size: 30px;
	margin-top: 15px;
	margin-left: 30px;
	font-weight: 600;
}

.leaderboard-student-name {
	font-weight: 600;
	font-size: 20px;
	margin-top: 15px;
}

.leaderboard-points {
	font-weight: 600;
	font-size: 18px;
	margin-top: 16px;
	right: 30px;
	color: #464646;
	position: absolute;
}

.assignments-box {
	width: 100%;
	margin: 0;
}

/* Screen smaller than 1050 */

@media only screen and (max-width: 1050px) {
	.assignments-box2 {
		width: 90%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 40px;
		position: relative;
	}

	.a-bloc-tabs {
		display: -webkit-flex;
		display: flex;
	}

	.a-add-new-button {
		background-color: transparent;
		width: 85px;
		border: none;
		margin-bottom: 10px;
		font-size: 13px;
	}

	.a-edit-button {
		background-color: transparent;
		width: 40px;
		border: none;
		margin-bottom: 10px;
		font-size: 13px;
		position: absolute;
		right: 0;
	}

	.a-add-icon {
		margin-bottom: 2px;
		margin-left: -2px;
		margin-right: 3px;
	}
}

/* Screen larger than 1050 */

@media only screen and (min-width: 1050px) {
	.assignments-box2 {
		width: 75%;
		right: 0;
		margin-left: 20.5%;
		margin-bottom: 40px;
		position: relative;
	}

	.a-bloc-tabs {
		display: -webkit-flex;
		display: flex;
		margin-top: 0px;
	}

	.a-add-new-button {
		background-color: transparent;
		width: 85px;
		border: none;
		margin-bottom: 10px;
		font-size: 13px;
		margin-top: 10px;
	}

	.a-edit-button {
		background-color: transparent;
		width: 40px;
		border: none;
		margin-bottom: 19px;
		font-size: 13px;
		position: absolute;
		right: 0;
		margin-top: 10px;
	}

	.a-add-icon {
		margin-bottom: 2px;
		margin-left: -2px;
		margin-right: 3px;
	}
}

/* Tab formatting */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background: #fff;
}

.a-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: relative;
	word-break: break-all;
}

.a-tabs {
	padding: 15px;
	text-align: center;
	width: 50%;
	background: #fdfdfd;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	font-weight: 200;
	color: #656565;
}

.a-active-tabs {
	background: #ececec;
	font-weight: 700;
	color: black;
}

.a-active-tabs::before {
	content: '';
	display: block;
	position: absolute;
	top: -5px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	width: calc(100% + 2px);
	height: 5px;
}

.a-content-tabs {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}

.a-content {
	background: #ececec;
	padding: 20px;
	width: 100%;
	height: 100%;
	display: none;
}

.a-active-content {
	display: block;
	min-height: 500px;
}

/* Content Formatting */

.a-container2 {
	position: relative;
}

.a-assignment-name-header {
	margin-left: 10px;
	font-weight: 600;
	width: 39%;
}

.a-date-posted-header {
	margin-left: 3%;
	font-weight: 600;
	width: 20%;
}
.a-due-date-header {
	margin-right: 19%;
	right: 0;
	position: absolute;
	font-weight: 600;
	width: 17%;
}

.a-submission-header {
	right: 3%;
	position: absolute;
	font-weight: 600;
	width: 13%;
}

.a-assignment-name-content {
	margin-left: 10px;
	color: #3e3e3e;
	font-weight: 300;
	width: 39%;
	background-color: transparent;
	text-align: left;
	border: none;
}

.a-date-posted-content {
	margin-left: 3%;
	color: #3e3e3e;
	font-weight: 300;
	width: 20%;
}
.a-due-date-content {
	margin-right: 19%;
	right: 0;
	position: absolute;
	color: #3e3e3e;
	font-weight: 300;
	width: 17%;
}

.a-submission-content {
	right: 3%;
	position: absolute;
	color: #3e3e3e;
	font-weight: 300;
	width: 13%;
	text-align: center;
}

.a-content-margin-top {
	margin-top: 30px;
}

.a-content-margin-top hr {
	margin-top: 10px;
}

.b-assignments-box {
	width: 100%;
	margin: 0;
}

/* Screen smaller than 1050 */

@media only screen and (max-width: 1050px) {
	.b-assignments-box2 {
		width: 90%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 40px;
		background-color: #ececec;
		position: relative;
		padding-bottom: 35px;
	}

	.b-bloc-tabs {
		display: -webkit-flex;
		display: flex;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
}

/* Screen larger than 1050 */

@media only screen and (min-width: 1050px) {
	.b-assignments-box2 {
		width: 75%;
		right: 0;
		margin-left: 20.5%;
		margin-bottom: 40px;
		position: relative;
		background-color: #ececec;
		top: 10px;
	}

	.b-bloc-tabs {
		display: -webkit-flex;
		display: flex;
		margin-top: 0px;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
}

/* Tab formatting */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.b-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: relative;
	word-break: break-all;
}

.b-tabs {
	padding: 5px;
	text-align: center;
	width: 50%;
	background: #ebebeb;
	cursor: pointer;
	box-sizing: content-box;
	position: relative;
	outline: none;
	font-weight: 200;
	color: #656565;
	margin: 10px;
	border: none;
	border-radius: 10px;
	font-size: 14px;
}

.b-active-tabs {
	background: #525252;
	font-weight: 700;
	color: white;
}

.b-active-tabs::before {
	content: '';
	display: block;
	position: absolute;
	top: -5px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	width: calc(100% + 2px);
	height: 5px;
}

.b-content-tabs {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	width: 92%;
	margin-left: auto;
	margin-right: auto;
}

.b-content {
	background: transparent;
	padding: 20px;
	width: 100%;
	display: none;
}

.b-active-content {
	display: block;
	min-height: 500px;
}

/* Content Formatting */

.b-container2 {
	position: relative;
}

.a-content-margin-top {
	margin-top: -7px;
}

.submission-table {
	background-color: white;
	width: 93%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 10px;
}

.sub-hr {
	margin-top: 10px;
}

/* Submission table contents */

.submission-student-name {
	margin-left: -15px;
	color: #3e3e3e;
	font-weight: 300;
	width: 40%;
	background-color: transparent;
	text-align: left;
	border: none;
	/* background-color: gray; */
}

.submission-review {
	margin-left: 3%;
	margin-top: 3px;
	color: #3e3e3e;
	font-weight: 300;
	width: 60px;
	font-size: 12px;
	/* background-color: greenyellow; */
}

.submission-date {
	right: 40px;
	position: absolute;
	color: #3e3e3e;
	font-weight: 300;
	width: 200px;
	text-align: center;
	/* background-color: hotpink; */
	font-size: 14px;
	margin-top: 2px;
}

.submission-attachment-button {
	background-color: transparent;
	border-radius: 5px;
	border: none;
	position: absolute;
	right: 25px;
}

.delete-submission-button {
	background-color: transparent;
	border-radius: 5px;
	border: none;
	position: absolute;
	right: -8px;
}

.a-details-title {
	margin-top: 35px;
	margin-left: 35px;
	font-size: 20px;
}

.assignments-title-and-openclose-button {
	/* background-color: yellow; */
	position: relative;
}

.a-switch {
	position: absolute;
	right: 0;
	margin-top: 36.5px;
	right: 80px;
}

.a-open-label {
	font-size: 14px;
	position: absolute;
	margin-top: 39px;
	right: 160px;
}

.a-close-label {
	font-size: 14px;
	position: absolute;
	margin-top: 39px;
	right: 32px;
}

/* Open and close Toggle */

.assignment-switch-label {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 25px;
}

.assignment-switch-label input {
	opacity: 0;
	width: 0;
	height: 0;
}

.a-slider {
	position: absolute;
	cursor: pointer;

	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	background-color: #267302;

	transition: 0.4s;
	border-radius: 34px;
}

.a-slider:before {
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	transition: 0.4s;
	border-radius: 34px;
}

input:checked + .a-slider {
	background-color: #9d9d9d;
}

input:checked + .a-slider:before {
	-webkit-transform: translateX(44px);
	        transform: translateX(44px);
}

/* Date Posted and Due Date */

.a-details-date-posted {
	margin-left: 35px;
	margin-top: 10px;
}

.a-details-date-posted-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-date-posted-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-details-due-date {
	margin-top: 10px;
	margin-left: 60px;
}

.a-details-due-date-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-due-date-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-due-date-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
}

.a-edit-icon {
	margin-top: -5px;
}

.a-assignment-details-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
	margin-top: 10px;
}

.a-assignment-details-label {
	font-weight: 700;
	font-size: 15px;
	margin-left: 35px;
	margin-top: 10px;
}

.a-assignment-details-tetxt {
	margin-top: -15px;
	font-size: 15px;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 35px;
}

.ar-assignments-box {
	width: 100%;
	margin: 0;
}

/* Screen smaller than 1050 */

@media only screen and (max-width: 1050px) {
	.ar-assignments-box2 {
		width: 90%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		background-color: #ececec;
		position: relative;
		padding-bottom: 75px;
		top: 10px;
	}
}

/* Screen larger than 1050 */

@media only screen and (min-width: 1050px) {
	.ar-assignments-box2 {
		width: 75%;
		right: 0;
		margin-left: 20.5%;
		margin-bottom: 40px;
		position: relative;
		background-color: #ececec;
		padding-bottom: 75px;
		top: 10px;
	}
}

/* Date Posted and Due Date */

.a-details-date-posted {
	margin-left: 35px;
	margin-top: 10px;
}

.a-details-date-posted-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-date-posted-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-details-due-date {
	margin-top: 10px;
	margin-left: 60px;
}

.a-details-due-date-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-due-date-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-due-date-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
}

.a-edit-icon {
	margin-top: -5px;
}

.a-assignment-details-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
	margin-top: 10px;
}

.a-assignment-details-label {
	font-weight: 700;
	font-size: 15px;
	margin-left: 35px;
	margin-top: 10px;
}

.a-assignment-details-tetxt {
	margin-top: -15px;
	font-size: 15px;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 35px;
}

.ar-assignment-title {
	padding-top: 35px;
	margin-left: 35px;
	font-size: 20px;
}

.ar-student-name {
	margin-left: 35px;
	font-size: 16px;
	font-weight: 700;
	margin-top: -20px;
}

.ar-submitted-date {
	margin-left: 15px;
	font-size: 13px;
	font-weight: 300;
	margin-top: -17px;
	font-style: italic;
}

.ar-preview-label {
	margin-left: 35px;
	font-size: 18px;
	margin-bottom: 0;
}

.ar-preview-box {
	margin-left: 35px;
	margin-top: 0px;
	margin-bottom: 30px;
	width: 92%;
	min-height: 500px;
	max-height: 700px;
	border-radius: 15px;
	border: 1px solid black;
	padding: 10px;
}

.ar-score-label {
	margin-left: 35px;
	font-size: 18px;
	margin-bottom: 0;
}

.ar-score-input {
	margin-left: 5px;
	height: 25px;
	width: 50px;
	border-radius: 8px;
	border: 1px solid black;
	text-align: center;
	font-size: 15px;
}

.ar-score-100 {
	margin-left: 7px;
	color: #4b4b4b;
	font-size: 15px;
	margin-top: 1.5px;
}

.ar-feedback-label {
	margin-left: 35px;
	font-size: 18px;
	margin-bottom: 0;
	margin-top: 10px;
}
.ar-feedback-input {
	margin-left: 35px;
	margin-top: 0px;
	margin-bottom: 30px;
	width: 92%;
	min-height: 200px;
	max-height: 500px;
	border-radius: 15px;
	border: 1px solid black;
	padding: 10px;
}

.ar-cancel-button {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: #666666;
	color: white;
	font-weight: 600;
	border: none;
	right: 365px;
}

.ar-cancel-button:hover {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: black;
	color: white;
	font-weight: 600;
	border: none;
	right: 365px;
}

.ar-save-button {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: #666666;
	color: white;
	font-weight: 600;
	border: none;
	right: 200px;
}

.ar-save-button:hover {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: black;
	color: white;
	font-weight: 600;
	border: none;
	right: 200px;
}

.ar-submit-button {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: #666666;
	color: white;
	font-weight: 600;
	border: none;
	right: 35px;
}

.ar-submit-button:hover {
	position: absolute;
	width: 150px;
	height: 40px;
	border-radius: 15px;
	background-color: black;
	color: white;
	font-weight: 600;
	border: none;
	right: 35px;
}

.homepage-box {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	/* background-color: cornflowerblue; */
}

.homepage-main-box {
	width: 100%;
	min-width: 400px;
}

.homepage-search-box {
	width: 60%;
	height: 150px;
	/* background-color: darkkhaki; */
	margin-left: auto;
	margin-right: auto;
	margin-top: 100px;
	margin-bottom: 150px;
}

.homepage-search-bar {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	position: relative;
}

.homepage-search-bar-input {
	display: block;
	width: 100%;
	height: 50px;
	font-size: 12px;
	padding-left: 40px;
	font-size: 16pt;
	border: none;
	border-bottom: 3px solid rgb(204, 204, 204);
	color: gray;
	font-weight: 500;
}

.homepage-search-bar-input-enter {
	position: absolute;
	right: 15px;
	top: 20px;
	height: 20px;
	width: 75px;
	min-width: 75px;
	color: rgb(204, 204, 204);
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.homepage-search-bar-input:focus {
	border-bottom: 3px solid rgb(63, 63, 63);
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.homepage-search-bar-input:focus ~ .search-icon {
	color: rgb(63, 63, 63);
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}

.homepage-search-bar-input:focus ~ .homepage-search-bar-input-enter {
	color: rgb(63, 63, 63);
	font-weight: 500;
}

.homepage-search-big-title {
	font-weight: 700;
	font-size: 40px;
	/* background-color: aliceblue; */
	color: #452724;
	/* letter-spacing: -2px; */
	line-height: 1;
}

.homepage-search-title {
	font-weight: 500;
	font-size: 24px;
	/* background-color: aliceblue; */
	margin-left: 5px;
	margin-bottom: 3px;
	margin-top: -10px;
	line-height: 1;
}

.homepage-search-subtitle {
	font-size: 16px;
	/* background-color: darkmagenta; */
	margin-bottom: 10px;
	margin-left: 5px;
}

/* Top 5 Books in Hong Kong */

.homepage-top-box {
	background-color: #fff7ec;
	padding-bottom: 50px;
	overflow: hidden;
}

.homepage-top-one-box {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	/* background-color: aqua; */
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	padding-left: 70px;
}

.homepage-top-title {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-weight: 700;
	font-size: 28px;
	/* background-color: aliceblue; */
	margin: 0;
	padding-top: 50px;
	margin-left: auto;
	margin-right: auto;
	color: #452724;
}

.homepage-top-one {
	text-align: center;
	overflow: hidden;
	width: 230px;
	height: 400px;
}

@media only screen and (min-width: 1166px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 1166px) and (min-width: 702px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-flow: dense;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 702px) and (min-width: 480px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-flow: dense;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 480px) {
	.homepage-top-box-content {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-auto-flow: dense;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}

	.homepage-top-title {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		font-weight: 700;
		font-size: 28px;
		margin: 0;
		padding-top: 50px;
		margin-left: auto;
		margin-right: auto;
		color: #452724;
		max-width: 270px;
		text-align: center;
	}
}

.top-five-number {
	font-size: 280px;
	font-weight: 700;
	/* background-color: burlywood; */
	padding-right: 100px;
	margin-bottom: -250px;
	margin-top: -50px;
	color: rgba(36, 50, 69, 0.5);
	pointer-events: none;
}

.homepage-top-book-title {
	font-weight: 600;
	font-size: 16px;
	margin-left: 5px;
	margin-bottom: 0;
}

.homepage-top-book-author {
	font-weight: 300;
	font-size: 13px;
	margin-left: 5px;
	margin-top: -5px;
}

/* Genres */

.homepage-genre-box {
	/* background-color: cornflowerblue; */
	padding-bottom: 50px;
}

.homepage-genre-title {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-weight: 700;
	font-size: 28px;
	/* background-color: aliceblue; */
	margin: 0;
	padding-top: 50px;
	margin-left: 5%;
	color: #452724;
}

.homepage-genre-subtitle {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-size: 16px;
	/* background-color: aliceblue; */
	margin: 0;
	margin-left: 5%;
	margin-bottom: 10px;
}

@media only screen and (min-width: 884px) {
	.homepage-genre-grid {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 884px) and (min-width: 500px) {
	.homepage-genre-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

@media only screen and (max-width: 500px) {
	.homepage-genre-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-content: space-between;
		        align-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

.homepage-genre-col {
	text-align: center;
	margin: 25px;
	width: 100px;
	height: 100px;
	-webkit-align-self: center;
	        align-self: center;
}

.homepage-genre-button {
	width: 100px;
	height: 100px;
	border-radius: 50px;
	border: none;
}

.homepage-genre-name {
	font-size: 14px;
}

/* Leave your contacts */

.homepage-contacts-box {
	background-color: #fff7ec;
}

.homepage-leave-contacts {
	/* background-color: lightpink; */
	padding-bottom: 40px;
}

.homepage-leave-contacts-title {
	font-weight: 700;
	font-size: 28px;
	/* background-color: aliceblue; */
	margin: 0;
	padding-top: 50px;
	margin-left: 5%;
	color: #452724;
}

.homepage-contacts {
	width: 85%;
	height: 27px;
	margin-left: 5%;
	margin-top: 25px;
	padding: 20px 10px 20px 10px;
	font-size: 20px;
	border: none;
	background-color: transparent;
	border-bottom: 2px solid grey;
	transition: -webkit-transform 0.2s ease;
	transition: transform 0.2s ease;
	transition: transform 0.2s ease, -webkit-transform 0.2s ease;
	font-size: 16px;
}
.homepage-contacts:focus {
	border-bottom: 3px solid #452724;
	-webkit-transform: translateY(-1px);
	        transform: translateY(-1px);
}

.homepage-contacts-occupation {
	width: 85%;
	height: 27px;
	margin-left: 5%;
	margin-top: 15px;
	padding-left: 7px;
	font-size: 13px;
	border: 1px solid #4d4d4d;
}

.homepage-contacts-image-box {
	/* background-color: darkkhaki; */
	text-align: center;
}

.homepage-contacts-submit-button {
	width: 120px;
	height: 35px;
	margin-left: 5%;
	margin-top: 40px;
	border-radius: 10px;
	margin-bottom: 10px;
	background-color: #ffce8a;
	color: #452724;
	font-weight: 500;
	font-size: 14px;
	border: none;
}

.homepage-contacts-submit-button:hover {
	background-color: black;
}

.homepage-message {
	font-size: 16px;
	overflow-wrap: break-word;
	word-wrap: normal;
	resize: none;
	word-break: break-all;
}

.homepage-signup-message {
	text-align: center;
	background-color: var(--main-color);
	padding: 10px;
	border-radius: 5px;
	-webkit-filter: opacity(0.7);
	        filter: opacity(0.7);
	color: black;
}
.homepage-signup-message-error {
	text-align: center;
	background-color: rgb(255, 101, 101);
	padding: 10px;
	border-radius: 5px;
	-webkit-filter: opacity(0.7);
	        filter: opacity(0.7);
	color: black;
}

.homepage-signup-message-container {
	position: fixed;
	top: 100px;
	width: 100%;
	z-index: 1000;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	justify-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.homepage-contacts-submit-button-disabled {
	width: 100px;
	height: 30px;
	margin-left: 5%;
	margin-top: 15px;
	border-radius: 10px;
	background-color: #808080;
	color: white;
	font-weight: 500;
	font-size: 13px;
	border: none;
}

.about-us-title-image {
	width: 100%;
}

.about-us-title-text {
	font-weight: 700;
	font-size: 35px;
	margin-top: -80px;
	margin-bottom: 90px;
	margin-left: 10%;
	color: white;
}

.abous-us-main-page-container {
	min-width: 400px;
}

.about-us-description {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.about-us-values-container {
	margin-top: 70px;
	background-color: #243245;
	padding-bottom: 90px;
}

.about-us-values-title {
	color: #ffce8a;
	font-weight: 700;
	font-size: 35px;
	margin-left: 10%;
	padding-top: 80px;
	padding-bottom: 40px;
}

.about-us-values-container-2 {
	width: 90%;
	/* background-color: lightgreen; */
	margin-left: 5%;
}

.about-us-team-container {
	background-color: #fff7ec;
	padding-bottom: 70px;
}

@media only screen and (min-width: 914px) {
	.about-us-team-inner-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
		margin-left: 50px;
		margin-right: 50px;
	}
}

@media only screen and (max-width: 914px) and (min-width: 480px) {
	.about-us-team-inner-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
		margin-left: 50px;
		margin-right: 50px;
	}
}

@media only screen and (max-width: 480px) {
	.about-us-team-inner-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		-webkit-align-items: center;
		        align-items: center;
		justify-items: center;
	}
}

.about-us-team-title {
	font-weight: 700;
	font-size: 35px;
	margin-left: 10%;
	padding-top: 80px;
	padding-bottom: 40px;
	color: #3c2a2d;
}

.about-us-team-profile-photo {
	width: 150px;
	height: 150px;
}

.about-us-team-profile-container {
	text-align: center;
	width: 200px;
	height: 250px;
}

.about-us-team-member-name {
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 0;
	color: #3c2a2d;
}

.about-us-team-member-title {
	font-size: 14px;
	margin-top: -5px;
	margin-bottom: 0;
	color: #3c2a2d;
}

p span {
	display: block;
}

.values-content {
	background-color: thistle;
	width: 50px;
	font-size: 20px;
}

.product-school-tab-button-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	justify-items: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	padding: 10px;
}

.product-school-tab-nixpage-classroom-button {
	width: 40%;
	height: 70%;
	background-color: #243245;
	color: #ffce8a;
	font-weight: 600;
	border: none;
	padding: 10px;
}

.product-school-tab-nixpage-classroom-button:hover {
	background-color: #131b25;
}

.product-school-tab-nixpage-button {
	height: 70%;
	background-color: #f5f5f5;
	color: #868686;
	font-weight: 600;
	border: none;
	width: 40%;
	padding: 10px;
}

.product-school-tab-nixpage-button:hover {
	background-color: #d6d6d6;
}

.product-school-title {
	margin-left: 10%;
	margin-top: 30px;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 0;
	color: #3c2a2d;
}

.product-school-coming-soon {
	margin-left: 21%;
	font-size: 40px;
	font-weight: 700;
	color: #ffce8a;
	margin-top: -20px;
	margin-bottom: 30px;
}

.product-school-intro {
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	background-color: #fff7ec;
	border-radius: 20px;
	padding: 20px;
}

.product-school-features-container {
	/* background-color: wheat; */
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	width: 80%;
}

/* FEATURE 1 */

.product-school-features-one-title {
	font-size: 25px;
	font-weight: 600;
	margin: 0;
	margin-top: 50px;
	color: #3c2a2d;
}

.product-school-features-one-paragraph {
	width: 100%;
	vertical-align: middle;
	min-width: 300px;
}

.product-school-features-one-animation {
	margin-top: 50px;
	width: 100%;
	margin-bottom: 20px;
	border: #7c7c7c 3px solid;
	border-radius: 25px;
	min-width: 300px;
}

.container-align-center {
	text-align: center;
}

.product-public-tab-button-container {
}

.product-public-features-container {
	margin-left: auto;
	margin-right: auto;
	margin-top: -20px;
	width: 80%;
}

.product-public-tab-nixpage-classroom {
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: 40px;
}

.product-public-tab-nixpage {
	margin-top: 20px;
	margin-left: 20px;
	margin-bottom: 40px;
}

.product-public-tab-nixpage-button {
	width: 100%;
	height: 40px;
	background-color: #243245;
	color: #ffce8a;
	font-weight: 600;
	border: none;
}

.product-public-tab-nixpage-button:hover {
	background-color: #131b25;
}

.product-public-tab-nixpage-classroom-button {
	width: 100%;
	height: 40px;
	background-color: #f5f5f5;
	color: #868686;
	font-weight: 600;
	border: none;
}

.product-public-tab-nixpage-classroom-button:hover {
	background-color: #d6d6d6;
}

.product-public-and-much-more {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 100%;
	font-size: 25px;
	font-weight: 700;
	color: #3c2a2d;
	margin-top: 20px;
	margin-bottom: 60px;
}

