
.wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  padding-top:10px;
  padding-bottom: 20px;
  outline: none;
}
.wrapper .input-data{
  height: 35px;
  width: 100%;
  position: relative;
  justify-content: center;
  align-content: center;
  border-radius: 0;
  outline: none;
}
.wrapper .input-data input{
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
  border-radius: 0;
  outline: none;
}
.input-data input:focus ~ label,
.input-data input:valid ~ label{
  transform: translateY(30px);
  font-size: 12px;
  color: #797979;
  border-radius: 0;
  outline: none;
}
.wrapper .input-data label{
  position: absolute;
  bottom: 0px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
  border-radius: 0;
  outline: none;
}
.input-data .underline{
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  border-radius: 0;
  outline: none;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #4158d0;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
  border-radius: 0;
  outline: none;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before{
  transform: scaleX(1);
  
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    -webkit-appearance: none;
}

