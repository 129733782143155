.product-school-tab-button-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	align-content: center;
	justify-content: space-evenly;
	padding: 10px;
}

.product-school-tab-nixpage-classroom-button {
	width: 40%;
	height: 70%;
	background-color: #243245;
	color: #ffce8a;
	font-weight: 600;
	border: none;
	padding: 10px;
}

.product-school-tab-nixpage-classroom-button:hover {
	background-color: #131b25;
}

.product-school-tab-nixpage-button {
	height: 70%;
	background-color: #f5f5f5;
	color: #868686;
	font-weight: 600;
	border: none;
	width: 40%;
	padding: 10px;
}

.product-school-tab-nixpage-button:hover {
	background-color: #d6d6d6;
}

.product-school-title {
	margin-left: 10%;
	margin-top: 30px;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 0;
	color: #3c2a2d;
}

.product-school-coming-soon {
	margin-left: 21%;
	font-size: 40px;
	font-weight: 700;
	color: #ffce8a;
	margin-top: -20px;
	margin-bottom: 30px;
}

.product-school-intro {
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	background-color: #fff7ec;
	border-radius: 20px;
	padding: 20px;
}

.product-school-features-container {
	/* background-color: wheat; */
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	width: 80%;
}

/* FEATURE 1 */

.product-school-features-one-title {
	font-size: 25px;
	font-weight: 600;
	margin: 0;
	margin-top: 50px;
	color: #3c2a2d;
}

.product-school-features-one-paragraph {
	width: 100%;
	vertical-align: middle;
	min-width: 300px;
}

.product-school-features-one-animation {
	margin-top: 50px;
	width: 100%;
	margin-bottom: 20px;
	border: #7c7c7c 3px solid;
	border-radius: 25px;
	min-width: 300px;
}

.container-align-center {
	text-align: center;
}
