.product-public-tab-button-container {
}

.product-public-features-container {
	margin-left: auto;
	margin-right: auto;
	margin-top: -20px;
	width: 80%;
}

.product-public-tab-nixpage-classroom {
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: 40px;
}

.product-public-tab-nixpage {
	margin-top: 20px;
	margin-left: 20px;
	margin-bottom: 40px;
}

.product-public-tab-nixpage-button {
	width: 100%;
	height: 40px;
	background-color: #243245;
	color: #ffce8a;
	font-weight: 600;
	border: none;
}

.product-public-tab-nixpage-button:hover {
	background-color: #131b25;
}

.product-public-tab-nixpage-classroom-button {
	width: 100%;
	height: 40px;
	background-color: #f5f5f5;
	color: #868686;
	font-weight: 600;
	border: none;
}

.product-public-tab-nixpage-classroom-button:hover {
	background-color: #d6d6d6;
}

.product-public-and-much-more {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 100%;
	font-size: 25px;
	font-weight: 700;
	color: #3c2a2d;
	margin-top: 20px;
	margin-bottom: 60px;
}
