.b-assignments-box {
	width: 100%;
	margin: 0;
}

/* Screen smaller than 1050 */

@media only screen and (max-width: 1050px) {
	.b-assignments-box2 {
		width: 90%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 40px;
		background-color: #ececec;
		position: relative;
		padding-bottom: 35px;
	}

	.b-bloc-tabs {
		display: flex;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
}

/* Screen larger than 1050 */

@media only screen and (min-width: 1050px) {
	.b-assignments-box2 {
		width: 75%;
		right: 0;
		margin-left: 20.5%;
		margin-bottom: 40px;
		position: relative;
		background-color: #ececec;
		top: 10px;
	}

	.b-bloc-tabs {
		display: flex;
		margin-top: 0px;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
}

/* Tab formatting */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.b-container {
	display: flex;
	flex-direction: column;
	position: relative;
	word-break: break-all;
}

.b-tabs {
	padding: 5px;
	text-align: center;
	width: 50%;
	background: #ebebeb;
	cursor: pointer;
	box-sizing: content-box;
	position: relative;
	outline: none;
	font-weight: 200;
	color: #656565;
	margin: 10px;
	border: none;
	border-radius: 10px;
	font-size: 14px;
}

.b-active-tabs {
	background: #525252;
	font-weight: 700;
	color: white;
}

.b-active-tabs::before {
	content: '';
	display: block;
	position: absolute;
	top: -5px;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + 2px);
	height: 5px;
}

.b-content-tabs {
	flex-grow: 1;
	width: 92%;
	margin-left: auto;
	margin-right: auto;
}

.b-content {
	background: transparent;
	padding: 20px;
	width: 100%;
	display: none;
}

.b-active-content {
	display: block;
	min-height: 500px;
}

/* Content Formatting */

.b-container2 {
	position: relative;
}

.a-content-margin-top {
	margin-top: -7px;
}

.submission-table {
	background-color: white;
	width: 93%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 10px;
}

.sub-hr {
	margin-top: 10px;
}

/* Submission table contents */

.submission-student-name {
	margin-left: -15px;
	color: #3e3e3e;
	font-weight: 300;
	width: 40%;
	background-color: transparent;
	text-align: left;
	border: none;
	/* background-color: gray; */
}

.submission-review {
	margin-left: 3%;
	margin-top: 3px;
	color: #3e3e3e;
	font-weight: 300;
	width: 60px;
	font-size: 12px;
	/* background-color: greenyellow; */
}

.submission-date {
	right: 40px;
	position: absolute;
	color: #3e3e3e;
	font-weight: 300;
	width: 200px;
	text-align: center;
	/* background-color: hotpink; */
	font-size: 14px;
	margin-top: 2px;
}

.submission-attachment-button {
	background-color: transparent;
	border-radius: 5px;
	border: none;
	position: absolute;
	right: 25px;
}

.delete-submission-button {
	background-color: transparent;
	border-radius: 5px;
	border: none;
	position: absolute;
	right: -8px;
}

.a-details-title {
	margin-top: 35px;
	margin-left: 35px;
	font-size: 20px;
}

.assignments-title-and-openclose-button {
	/* background-color: yellow; */
	position: relative;
}

.a-switch {
	position: absolute;
	right: 0;
	margin-top: 36.5px;
	right: 80px;
}

.a-open-label {
	font-size: 14px;
	position: absolute;
	margin-top: 39px;
	right: 160px;
}

.a-close-label {
	font-size: 14px;
	position: absolute;
	margin-top: 39px;
	right: 32px;
}

/* Open and close Toggle */

.assignment-switch-label {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 25px;
}

.assignment-switch-label input {
	opacity: 0;
	width: 0;
	height: 0;
}

.a-slider {
	position: absolute;
	cursor: pointer;

	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	background-color: #267302;

	transition: 0.4s;
	border-radius: 34px;
}

.a-slider:before {
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	transition: 0.4s;
	border-radius: 34px;
}

input:checked + .a-slider {
	background-color: #9d9d9d;
}

input:checked + .a-slider:before {
	transform: translateX(44px);
}

/* Date Posted and Due Date */

.a-details-date-posted {
	margin-left: 35px;
	margin-top: 10px;
}

.a-details-date-posted-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-date-posted-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-details-due-date {
	margin-top: 10px;
	margin-left: 60px;
}

.a-details-due-date-label {
	font-weight: 700;
	font-size: 15px;
}

.a-details-due-date-date {
	margin-top: -15px;
	font-size: 15px;
}

.a-due-date-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
}

.a-edit-icon {
	margin-top: -5px;
}

.a-assignment-details-edit-button {
	height: 20px;
	margin-left: 5px;
	background-color: transparent;
	border: none;
	margin-top: 10px;
}

.a-assignment-details-label {
	font-weight: 700;
	font-size: 15px;
	margin-left: 35px;
	margin-top: 10px;
}

.a-assignment-details-tetxt {
	margin-top: -15px;
	font-size: 15px;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 35px;
}
